import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LinkIcon, CheckIcon } from "@heroicons/react/solid";
import { DateTime } from "luxon";
import { BookingContext } from "./";
import { useToast } from "../../context/ToastContext";
import { IOrderLineItem } from "shopify-api-node";
import { IBookingContext, IDriver } from "../../interfaces/BookingRegistration";

export default function BookingRegistration() {
  const bookingContext = useContext(BookingContext);
  const { order, booking } = bookingContext as IBookingContext;
  let navigate = useNavigate();
  const { bookingId } = useParams();

  const [lineItems, setLineItems] = useState([] as IOrderLineItem[]);

  const [bookingDate, setBookingDate] = useState("");

  const toast = useToast();

  const skuToSessionDate = (sku: string): string => {
    if (!sku) {
      return "";
    }
    const matchSku = sku.match(/(2[2-3][0-1][0-9][0-3][0-9]{3})\w+/g);
    if (matchSku) {
      const matched = matchSku[0];
      const yy = matched.slice(0, 2);
      const mm = matched.slice(2, 4);
      const dd = matched.slice(4, 6);
      let HH = matched.slice(6, 8);
      const MM = matched.slice(8, 10);
      let offset = 0;
      const nextDaySession = parseInt(HH) > 23 ? true : false;

      try {
        if (nextDaySession) {
          offset = parseInt(HH) - 23;
          HH = "23";
          const sessionDate = DateTime.fromISO(
            `20${yy}-${mm}-${dd}T${HH}:${MM}:00`,
            { zone: "Australia/Sydney" }
          );
          sessionDate.plus({ hours: offset });
          return sessionDate.toString();
        } else {
          const sessionDate = DateTime.fromISO(
            `20${yy}-${mm}-${dd}T${HH}:${MM}:00`,
            { zone: "Australia/Sydney" }
          );
          return sessionDate.toString();
        }
      } catch (error) {
        console.log("Error parsing date from SKU");
        return "";
      }
    } else {
      return "";
    }
  };

  const shouldDisplayRegisterDriverBtn = true;
  // (booking?.drivers?.length ?? Infinity) < 16;

  useEffect(() => {
    const getOrderData = async () => {
      const lineItems = order?.line_items || [];
      setLineItems(lineItems);
      const order_date = order?.created_at || "";
      setBookingDate(
        DateTime.fromJSDate(new Date(order_date)).toLocaleString()
      );
    };
    getOrderData();
  }, [bookingContext, order]);

  const racesBlock = lineItems.map((lineItem, i) => {
    const date = skuToSessionDate(lineItem.sku);
    const dateString = `${DateTime.fromJSDate(new Date(date)).toFormat(
      "DDDD"
    )} | ${DateTime.fromJSDate(new Date(date)).toLocaleString(
      DateTime.TIME_SIMPLE
    )}`;

    console.log("LI", lineItem);

    return (
      <div
        key={i}
        className="mb-2.5 px-5 py-4 flex justify-between items-center border-solid border border-inherit rounded-lg"
      >
        <div className="flex flex-col mr-1.5 text-sm font-medium text-gray-900">
          <div>{dateString}</div>
          <div className="text-gray-600 text-xs">{lineItem.variant_title}</div>
        </div>
        <div className="flex items-right">
          <div className="text-sm font-normal text-gray-500">
            {lineItem.quantity} driver{lineItem.quantity !== 1 ? "s" : ""}
          </div>
        </div>
      </div>
    );
  });

  const driverBlock = booking?.drivers?.map((driver: IDriver, i: number) => {
    console.log(driver);
    return (
      <div className="flex items-center justify-between mb-4" key={i}>
        <div className="flex items-center">
          <div className="min-w-min mr-10">
            <div className="bg-gray-100 rounded-full text-center flex items-center justify-center w-10 h-10 text-base font-semibold">
              {i + 1}
            </div>
          </div>
          <div className="text-ellipsis overflow-hidden whitespace-nowrap inline-block sm:w-[100%] w-[140px]">
            {driver.first_name} {driver.last_name}
          </div>
        </div>
        <div className="w-6 h-6 flex items-center justify-center">
          <div className="w-5	h-5 rounded-full flex items-center justify-center bg-lime-500	">
            <CheckIcon className="w-4	h-4 text-white" />
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="sm:bg-[url('./images/hyperkating_bg.jpg')] bg-no-repeat	bg-cover bg-black fixed inset-0 z-[-1]"></div>
      <div className="p-5 sm:p-0 sm:py-10 sm:min-h-full flex items-center justify-center">
        <div className="p-5 flex flex-col bg-white rounded-xl shadow-sm sm:max-w-[550px] sm:mx-auto sm:p-12">
          <div className="text-black text-2xl font-semibold mb-1.5 sm:text-center">
            {order.customer?.first_name
              ? `${order.customer?.first_name} ${
                  order.customer?.last_name || ""
                }'s Booking`
              : `Booking`}
          </div>
          <div className="mb-4 text-gray-500 text-base font-semibold sm:text-center hidden">
            {bookingDate}
          </div>
          <div className="mb-4 font-normal text-base text-gray-500 sm:text-center">
            Please ensure all drivers complete registration and our waiver
            before check-in at the venue.
          </div>
          <div className="mb-4 font-normal text-base text-gray-500 sm:text-center">
            Share this link with other drivers so they can complete the
            registration process:
          </div>
          <button
            className="mb-4 bg-indigo-700 px-4 py-2 rounded-full flex flex-row self-center cursor-pointer text-white text-sm"
            onClick={() => {
              toast.displayToast({ message: "Link copied to clipboard" });
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            <LinkIcon className="h-6 w-6 text-white place-self-center" />
            &nbsp;Copy Link
          </button>
          <div>{racesBlock}</div>
          <div className="mt-8 mb-2.5">
            <div className="text-black-500 text-xl font-medium border-b	pb-2.5 sm:text-center">
              Registered drivers
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between mb-4 pl-2.5">
              <div className="flex items-center justify-between">
                <div className="text-sm	font-normal	text-gray-500 mr-12">No</div>
                <div className="text-sm	font-normal	text-gray-500">
                  Driver Name
                </div>
              </div>
              <div className="text-sm	font-normal	text-gray-500">Status</div>
            </div>
            {driverBlock}
            {!booking?.drivers?.length && (
              <div className="mt-8 mb-2.5">
                <div className="text-black-500 text-sm font-medium border-b	pb-2.5 sm:text-center">
                  No drivers registered
                </div>
              </div>
            )}
          </div>

          {shouldDisplayRegisterDriverBtn && (
            <div
              className="text-center self-center items-center mt-5 p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[335px] min-w-[100%] cursor-pointer"
              onClick={() => navigate(`/bookings/${bookingId}/register-driver`)}
            >
              Register Now
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useForm } from "@formspree/react";

export const PixelWall = () => {
  const [selectedFile, setSelectedFile] = useState(null as File | null);
  const [text, setText] = useState(""); // State to store entered text
  const [textColor, setTextColor] = useState("#FF0000"); // State to store text color
  const [selectedCorp, setSelectedCorp] = useState("Choose a Corp"); // State to store selected corporation
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const reader = new FileReader();
  const [state, handleSubmit] = useForm("mvoywval");

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // handle validations
    if (!e.target.files) {
      return;
    }

    const uploadedFile = e.target.files[0];

    //Read the contents of Image File.
    reader.readAsDataURL(uploadedFile);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e?.target?.result as string;

      //Validate the File Height and Width.
      image.onload = function () {
        var height = image.height;
        var width = image.width;
        if (height !== 27 || width !== 224) {
          alert("Dimensions must be 224x27");
          return false;
        }
        setSelectedFile(uploadedFile);
        return true;
      };
    };
  };

  const handleGenerate = () => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();

    // Convert the data URL to Blob
    const blob = dataURLtoBlob(dataURL);

    // Set additional properties for the File
    const fileName = "generatedImage.png";
    const lastModified = new Date().getTime();
    // Create a File with additional properties
    const file = new File([blob], fileName, { lastModified, type: "image/png" });

    // Set the generated image as the selected file
    setSelectedFile(file);

    // Create a new DataTransfer object and set the files on it
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    const hiddenFileInput = document.getElementById("upload") as HTMLInputElement;
    hiddenFileInput.files = dataTransfer.files;
  };

  // Function to convert data URL to Blob
  function dataURLtoBlob(dataURL: string): Blob {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1].split(";")[0];
    const raw = window.atob(parts[1]);
    const array = new Uint8Array(new ArrayBuffer(raw.length));

    for (let i = 0; i < raw.length; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Blob([array], { type: contentType });
  }

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      console.error("Could not get 2D context for canvas");
      return;
    }

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    var centerX = canvas.width / 2;
    var centerY = canvas.height / 2;

    // Draw the text on the canvas with the specified color
    ctx.fillStyle = textColor;
    ctx.font = "600 18px Montserrat"; // Adjust the font size and family as needed
    ctx.fillText(text, centerX, centerY); // Adjust the position as needed
  }, [text, textColor]);

  return (
    <>
      <div className="min-h-full">
        <div className="xl:pl-64 flex flex-col">
          <main className="flex-1">
            <div className="pt-8 px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900 pb-3">Pixel Wall</h1>
                  <div className="max-w-sm mb-4">
                    <div className="mb-5">
                      <label className="block mb-2 text-sm font-medium text-gray-900 text-black">Enter Text</label>
                      <input
                        type="text"
                        id="textInput"
                        onChange={(e) => setText(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="mb-5">
                      <label className="block mb-2 text-sm font-medium text-gray-900 text-black">Text Color</label>
                      <input
                        type="color"
                        id="textColor"
                        value={textColor}
                        onChange={(e) => setTextColor(e.target.value)}
                        required
                        autoComplete="off"
                        className="h-8 w-25 bg-gray-50 border border-gray-300"
                      />
                    </div>
                    <div className="mb-5">
                      <label className="block mb-2 text-sm font-medium text-gray-900 text-black">Preview</label>
                      <canvas ref={canvasRef} id="textCanvas" width="224" height="27" className="bg-black border-1 border-black"></canvas>
                    </div>
                    <button
                      type="button"
                      onClick={handleGenerate}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <hr className="mb-4 mx-4 sm:mx-6 lg:mx-8" />

            <div className="px-4 sm:px-6 lg:px-8">
              <form onSubmit={handleSubmit} className="flex flex-col space-between">
                {selectedCorp === "Corp 1" && <input type="hidden" name="title" value="BallWall_Corp1" />}
                {selectedCorp === "Corp 2" && <input type="hidden" name="title" value="BallWall_Corp2" />}
                {selectedCorp === "Both Corps" && (
                  <>
                    <input type="hidden" name="title" value="Ballwall_Both" />
                  </>
                )}
                <div className="max-w-xl mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload File</label>
                  <label className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none relative">
                    <span className="flex items-center space-x-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>
                      <span className="font-medium text-gray-600">Drop file to Attach, or click to browse</span>
                    </span>
                    <input type="file" name="upload" id="upload" className="absolute w-full h-full opacity-0" onChange={handleFileInput} />
                  </label>
                </div>
                {selectedFile && selectedCorp === "Choose a Corp" && <p className="font-medium text-gray-600 mb-[10px]">Choose a corp to upload the file</p>}
                <div className="flex items-center gap-[12px]">
                  <button
                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-fit ${
                      !selectedFile || selectedCorp === "Choose a Corp" ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    type="submit"
                    disabled={(!selectedFile || state.submitting) && selectedCorp === "Choose a Corp"}
                  >
                    Upload
                  </button>
                  <div>
                    <select
                      value={selectedCorp}
                      onChange={(e) => setSelectedCorp(e.target.value)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-80"
                    >
                      <option value="Choose a Corp" disabled>
                        Choose Image
                      </option>
                      <option value="Corp 1">Corp 1</option>
                      <option value="Corp 2">Corp 2</option>
                      <option value="Both Corps">Both Corps</option>
                    </select>
                  </div>
                </div>
              </form>
              {state.succeeded ? <div>File has been uploaded successfully</div> : null}
              {selectedFile ? (
                <div className="relative bg-black mt-5" style={{ height: "108px", width: "896px" }}>
                  <img style={{ height: "108px", width: "896px" }} className="absolute" src={URL.createObjectURL(selectedFile)} alt="Pixel Wall" />
                  <div
                    style={{
                      height: "108px",
                      width: "896px",
                      borderLeft: "80px solid #ffffff94",
                      borderRight: "80px solid #ffffff94",
                      borderTop: "12px solid #ffffff94",
                      borderBottom: "12px solid #ffffff94",
                    }}
                    className="absolute"
                  ></div>
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

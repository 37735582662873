import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ILineItemWithOrder,
  ISessionWithBookings,
} from "../../interfaces/Session";
import { TBookingType } from "../../interfaces";
import { getDriverCount } from "./utilities";
import { DateTime } from "luxon";
import { useFirebase } from "../../components/Firebase";
import { doc, getDoc } from "firebase/firestore";
import { isBreakOrder } from "../../utilities";
import { TBookings } from "../../context/DriverRegistration";
import { useStaffInfo } from "../../context/Staff";

interface Props {
  session: ISessionWithBookings;
  view: TBookingType;
  registrationBookings: TBookings;
}
function useSession({ session, view, registrationBookings }: Props) {
  const [rrmLog, setRrmLog] = useState<any>();
  const [grmLogs, setGrmLogs] = useState<any>({});

  const { data: staffData } = useStaffInfo();
  const firebase = useFirebase();
  
  const db = useMemo(() => firebase.db, [firebase]);

  const sessionTime = useMemo(
    () =>
      view === "MAIN_TRACK"
        ? session.option3?.trim()?.toLowerCase()
        : session.option1?.trim()?.toLowerCase(),
    [view, session]
  );

  const staffCount = useMemo(() => {
    return sessionTime
      ? staffData[
        view === "MAIN_TRACK"
          ? "mainTrack"
          : view === "MINI_TRACK"
            ? "miniTrack"
            : "vr"
      ]?.[sessionTime]?.length || 0
      : 0;
  }, [staffData, view, sessionTime]);

  const genuineBookings = useMemo(() => {
    const bookingsWithOutBreak = session.bookings.filter(
      (booking) => !isBreakOrder(booking.order)
    );
    return bookingsWithOutBreak;
  }, [session]);

  const isBreakRace = useMemo(
    () => session.bookings.length > 0 && genuineBookings.length === 0,
    [genuineBookings, session]
  );

  const filteredBookings = useMemo(() => {
    return genuineBookings.filter(
      (booking) =>
        getDriverCount(booking) && booking.order.financial_status !== "refunded"
    );
  }, [genuineBookings]);

  const getBookingTime = useCallback((booking: ILineItemWithOrder) => {
    const createdAt = DateTime.fromISO(booking.order.created_at);
    return createdAt.isValid
      ? createdAt.toFormat("ccc LLL dd kkkk - HH:mm")
      : "";
  }, []);

  const getSessionPrice = useCallback((booking: ILineItemWithOrder) => {
    const lineItem = booking.order.line_items.find(
      (li) => li.id === booking.id
    );

    if (!lineItem) {
      return null;
    }

    const total = lineItem.price_set.shop_money;
    const discount = lineItem.total_discount_set.shop_money;
    const quantity = lineItem.quantity;

    return +total.amount * quantity - +discount.amount;
  }, []);

  const getAvgSessionPrice = useCallback(
    (booking: ILineItemWithOrder) => {
      const lineItem = booking.order.line_items.find(
        (li) => li.id === booking.id
      );

      const sessionPrice = getSessionPrice(booking);

      if (!lineItem || sessionPrice === null) {
        return null;
      }

      return sessionPrice / lineItem.quantity;
    },
    [getSessionPrice]
  );

  const getSessionPriceCurrency = useCallback((booking: ILineItemWithOrder) => {
    const lineItem = booking.order.line_items.find(
      (li) => li.id === booking.id
    );

    if (!lineItem) {
      return null;
    }

    const total = lineItem.price_set.shop_money;
    return total.currency_code;
  }, []);

  const getPaidPriceContent = useCallback(
    (booking: ILineItemWithOrder) => {
      const sessionPrice = getSessionPrice(booking);
      if (sessionPrice === null) {
        return "---";
      }
      return `${sessionPrice} (${getAvgSessionPrice(
        booking
      )}) ${getSessionPriceCurrency(booking)}`;
    },
    [getSessionPrice, getAvgSessionPrice, getSessionPriceCurrency]
  );

  //When there is no genuine customer or any booking is not made
  const showOpenOrCloseRace = useMemo(
    () => isBreakRace || session.bookings.length === 0,
    [isBreakRace, session]
  );

  const RowClasses = useMemo(
    () =>
      showOpenOrCloseRace
        ? session.inventory_quantity > 0
          ? "bg-[#DBFFD1]"
          : "bg-[#FAD8FF]"
        : "",
    [showOpenOrCloseRace, session]
  );

  const sessionColor = useMemo(() => {
    let minute = sessionTime?.split(":")?.[1];
    if (minute) {
      minute = minute.replace("am", "");
      minute = minute.replace("pm", "");
      switch (minute) {
        case "00":
          return "bg-[#C9F5B1]";
        case "15":
          return "bg-[#F5B1B1]";
        case "30":
          return "bg-[#B1C3F5]";
        case "45":
          return "bg-[#F1F5B1]";
        default:
          return "bg-[#e9fbff]";
      }
    }
    return undefined;
  }, [sessionTime]);

  useEffect(() => {
    const sku = session.sku;
    getDoc(doc(db, "logs", `RRM#${sku}#${view}`))
      .then((data) => {
        if (data.exists()) {
          setRrmLog(data.data());
        }
      })
      .catch((err) => {
        console.log("Failed to load rrmLog", err);

        setRrmLog(undefined);
      });
  }, [session, view, db]);

  useEffect(() => {
    if (filteredBookings.length && view === "VR") {
      const logRef = db.collection("logs");

      const chunkSize = 10;
      const res = [];

      const orderIds = filteredBookings.map((booking) => booking.order.id);

      for (let i = 0; i < orderIds.length; i += chunkSize) {
        const chunk = orderIds.slice(i, i + chunkSize);
        res.push(chunk);
      }

      Promise.all(
        res.map((idsChunk) =>
          logRef
            .where("type", "==", "GOOGLE_REVIEW_MESSAGE")
            .where("orderId", "in", idsChunk)
            .get()
        )
      )
        .then((logsSnapshot: any) => {
          const logSnapshotData = logsSnapshot.map((query: any) =>
            query?.docs?.map((doc: any) => doc?.data())
          );

          let logs = [].concat(...logSnapshotData).reduce((acc, ele: any) => {
            const copy: any = { ...acc };
            copy[ele.orderId] = ele;
            return copy;
          }, {});

          setGrmLogs(logs);
        })
        .catch((err) => {
          console.log("Failed to load grmLogs", err);

          setGrmLogs({});
        });
    }
  }, [db, filteredBookings, view]);

  const getRegisteredDriversCount: (booking: ILineItemWithOrder) => number = useCallback((booking) => {
    const drivers = registrationBookings[booking.order.id]?.drivers;
    return Array.isArray(drivers) ? drivers.length : 0;
  },[registrationBookings]);

  return {
    RowClasses, // classes for tr
    sessionColor,
    staffCount,
    sessionTime,
    filteredBookings,
    isBreakRace,
    getBookingTime,
    getPaidPriceContent,
    showOpenOrCloseRace,
    rrmLog,
    grmLogs,
    getRegisteredDriversCount,
  };
}

export default useSession;

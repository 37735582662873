import React, { useCallback, useMemo } from "react";
import { ISessionWithBookings } from "../../interfaces/Session";
import { useBookingSession } from "../../context/BookingSession";
import { EXPERT_RACE_TIMINGS } from "../../constants/Dashboard";
import { CheckIcon } from "@heroicons/react/solid";
import { usePricechanger } from "../../context/PriceChanger";
import {
  MAIN_TRACK_PRICE_OPTIONS,
  MINI_TRACK_PRICE_OPTIONS,
  VR_PRICE_OPTIONS,
} from "../../constants/priceChanger";
import { IPriceRecommendation } from "../../interfaces";

interface Props {
  session: ISessionWithBookings;
  recommendation?: IPriceRecommendation
}
function PriceRow({ session, recommendation }: Props) {
  const { track: view } = useBookingSession();
  const {
    isItemSelected,
    isPriceSelected,
    toggleItem,
    removeItemByVariant,
    getBgColor
  } = usePricechanger();

  const sessionTime = useMemo(
    () =>
      view === "MAIN_TRACK"
        ? session.option3?.trim()?.toLowerCase()
        : session.option1?.trim()?.toLowerCase(),
    [view, session]
  );

  const bgColor = useMemo(() => getBgColor(view),[view, getBgColor]);

  const price = useMemo(() => Number(session.price), [session]);
  const variantId = useMemo(() => session.id, [session]);
  const isSessionSelected = useMemo(
    () => isItemSelected(variantId),
    [variantId, isItemSelected]
  );
  const priceOptions = useMemo(
    () =>
      view === "MAIN_TRACK"
        ? MAIN_TRACK_PRICE_OPTIONS
        : view === "MINI_TRACK"
        ? MINI_TRACK_PRICE_OPTIONS
        : VR_PRICE_OPTIONS,
    [view]
  );

  return (
    <tr>
      {/* checkbox */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            {isSessionSelected ? (
              <div
                className={`rounded-md p-0.5 ${bgColor}`}
                onClick={() => {
                  removeItemByVariant(variantId);
                }}
              >
                <CheckIcon className={`w-4 cursor-pointer text-white`} />
              </div>
            ) : (
              <div className="border-[3px] border-solid border-gray-200 rounded-md p-1.5"></div>
            )}
          </div>
        </div>
      </td>

      {/* Session/Time */}
      <td className={`px-2 py-3 max-w-0 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {sessionTime}
              <br />
              {view === "MAIN_TRACK" ? (
                <>
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {session.option1}
                  </span>
                  <br />
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {EXPERT_RACE_TIMINGS.includes(
                      session.option3?.trim()?.toLowerCase() || ""
                    )
                      ? "Expert"
                      : "Standard"}
                  </span>
                </>
              ) : view === "MINI_TRACK" ? (
                <>
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {session.option3}
                  </span>
                  <br />
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {EXPERT_RACE_TIMINGS.includes(
                      session.option1?.trim()?.toLowerCase() || ""
                    )
                      ? "Expert"
                      : "Standard"}
                  </span>
                </>
              ) : (
                <>
                  <small className="font-medium text-xs leading-tight text-gray-700">
                    {session.option2}
                  </small>
                </>
              )}
              <br />
            </span>
          </div>
        </div>
      </td>
      {/* Quantoty */}
      <td className={`px-2 py-3 max-w-0 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0 justify-center">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {session.inventory_quantity}
            </span>
          </div>
        </div>
      </td>

      {/* checkbox */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 hover:text-gray-600 gap-2">
          {priceOptions.map((p) => (
            <PriceBlock
              key={p}
              bgColor={bgColor}
              isActualPrice={price === p}
              isSelected={isPriceSelected({
                variantId,
                price: p,
              })}
              onClick={() => {
                toggleItem({
                  variantId,
                  price: p,
                });
              }}
              price={p}
              recommendation={recommendation?.VARIANT_RECOMMENDED_PRICE === p ? recommendation : undefined}
            />
          ))}
        </div>
      </td>
    </tr>
  );
}

interface IPriceBlockProps {
  isActualPrice: boolean;
  isSelected: boolean;
  price: number;
  bgColor: string;
  onClick: (val: number) => void;
  recommendation?: IPriceRecommendation
}
function PriceBlock({
  isActualPrice,
  isSelected,
  price,
  bgColor,
  onClick,
  recommendation
}: IPriceBlockProps) {
  const handleClickPriceBlock = useCallback(() => {
    onClick(price);
  },[onClick, price])
  
  return (
    // <div
    //   className={`font-bold border border-gray-300 rounded-md text-center p-3 flex justify-center items-center cursor-pointer ${
    //     isActualPrice ? "text-gray-600 bg-gray-300 pointer-events-none" : ""
    //   } ${isSelected ? `${bgColor} text-white` : ""} ${recommendation ? "border-orange-500" : ""}`}
    //   onClick={() => onClick(price)}
    // >
    //   ${price}
    // </div>
    <div className={`h-full hk-gradient-bg cursor-pointer rounded-md ${(recommendation) ? "p-1" : ""}`} onClick={handleClickPriceBlock}>
      <div className={`h-full font-bold border border-gray-300 rounded-md text-center p-3 flex justify-center items-center cursor-pointer ${isActualPrice ? "text-gray-600 bg-gray-300 pointer-events-none" : ""} ${isSelected ? `${bgColor} text-white` : "bg-white"}`}>
        ${price}
      </div>
    </div>
  );
}

export default PriceRow;

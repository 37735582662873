import React, { Fragment } from "react";
import { useBookingSession } from "../../context/BookingSession";
import LoadingSpinner from "../../components/Shared/LoadingSpinner";
import { ISessionWithBookings } from "../../interfaces/Session";
import PriceRow from "./PriceRow";
import { BookingDriverSeats } from "../../components/Common";
import { usePricechanger } from "../../context/PriceChanger";

function PriceTable() {
  const { loading, filteredSessions, track } = useBookingSession();
  const { recommendedPrices } = usePricechanger()
  const ageOptions = ["All Sessions"];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="dashboard-session-table-wrapper m-7 h-[calc(100%_-_3.5rem)] overflow-auto">
      <table className="min-w-full h-[1px]">
        <thead>
          <tr>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 w-1/12"
              scope="col"
            >
              Checklist
            </th>
            {/* TODO: add filter based on view and filter the session data */}
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 w-2/12"
              scope="col"
            >
              <select
                className="w-full rounded-md py-2 pl-3 pr-10"
                defaultValue="All Sessions"
              >
                {ageOptions.map((opt) => {
                  return <option key={opt}>{opt}</option>;
                })}
              </select>
            </th>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 w-1/12"
              scope="col"
            >
              Qty
            </th>
            <th
              className="p-2 font-medium text-xs leading-none text-gray-700 bg-gray-100 uppercase tracking-wider sticky -top-1 z-10 w-6/12 text-left"
              scope="col"
            >
              New Prices
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {filteredSessions?.length !== 0 &&
            filteredSessions.map((session: ISessionWithBookings) => (
              <Fragment key={session.id}>
                <PriceRow session={session} recommendation={recommendedPrices.find(recommendedPrice => recommendedPrice.VARIANT_ID === session.id)} />
                <tr>
                  <td colSpan={4} className="p-0">
                    <BookingDriverSeats session={session} view={track}/>
                  </td>
                </tr>
              </Fragment>
            ))}
          {filteredSessions.length === 0 && (
            <tr>
              <td colSpan={4}>
                <div className="text-lg text-gray-400 text-center h-screen mt-[20%]">
                  No records to display
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PriceTable;

import { FC, ReactNode, useCallback, useMemo } from "react";

interface Props {
  children?: ReactNode | string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: "medium" | "small";
  className?: string;
  type?: "primary" | "secondary";
  withCustomColor?: boolean;
}
const Button: FC<Props> = ({
  children = "",
  onClick,
  loading = false,
  disabled = false,
  variant = "medium",
  className = "",
  type = "primary",
  withCustomColor = false
}) => {
  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  const primary = useMemo(() => type === "primary", [type]);

  return (
    <button
      className={`h-full flex items-center text-sm font-bold w-fit rounded-[100px] font-[Montserrat] ${
        !withCustomColor && (primary ? "bg-[#9D62FE] text-white" : "bg-white text-[#9D62FE] border-[#9D62FE] border border-solid")
      } ${
        disabled
          ? "bg-opacity-50 text-opacity-80 pointer-events-none border-opacity-50"
          : ""
      } ${variant === "small" ? "px-2.5 py-1" : "px-4 py-2"} ${className}`}
      onClick={handleClick}
      disabled={disabled}
      data-type={type}
    >
      {loading && <>Loading...</>}
      {!loading && children}
    </button>
  );
};

export default Button;

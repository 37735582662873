const ROUTES = {
  ROOT: "/",
  DASHBOARD: "/dashboard",
  VR_DASHBOARD: "/vr-dashboard",
  LOGIN: "/login",
  PAGE_NOT_FOUND: "/page-not-found",
  BOOKING_REGISTRATION: "/bookings/:bookingId",
  COMMISSION_SCREEN: "/commission",
  MEMBERS: "/members",
  CALENDAR: "/calendar",
  PIXEL_WALL: "/pixel-wall",
  REGISTER_DRIVER: "/register-driver",
  STAFF: "/staff",
  PRICE_CHANGER: "/price-changer",
  ARCADE_CARDS: "/arcade-card",
  SMS_LOGS: "/sms-logs",
  EVENTS_DAY: "/events",
  ISSUE: "/issue",
  LOGS: "/logs",
  SPEED_CONTROL: "/speed-control",
  APP_LOGS: "/app-logs",
};

export { ROUTES };

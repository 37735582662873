import React from "react";
import { PageLayout } from "../../layouts";
import BookingSessionProvider from "../../context/BookingSession";
import Header from "./Header";
import { TBookingType } from "../../interfaces";
import PriceTable from "./PriceTable";
import PriceChangerProvider, {
  usePricechanger,
} from "../../context/PriceChanger";
import ModalProvider from "../../context/Modal";

export function PriceChanger() {
  return (
    <PriceChangerProvider>
      <PriceChnagerComponent />
    </PriceChangerProvider>
  );
}

function PriceChnagerComponent() {
  const { view, selectedDate, changeSelectedDate, changeView } = usePricechanger();
  return (
    <BookingSessionProvider date={selectedDate} track={view}>
      <ModalProvider>
        <PageLayout
          Header={
            <Header
              changeSelectedDate={changeSelectedDate}
              changeView={(val) => {
                changeView(val as TBookingType);
              }}
            />
          }
        >
          <PriceTable />
        </PageLayout>
      </ModalProvider>
    </BookingSessionProvider>
  );
}

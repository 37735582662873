import { useCallback, useMemo, useState } from "react";
import { ISessionWithBookings } from "../../interfaces/Session";
import axios from "axios";
import {
  NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK,
  NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK,
  NUMBER_OF_SEATS_IN_A_VR,
} from "../../constants/Dashboard";
import { TBookingType } from "../../interfaces";
import { IOrder } from "shopify-api-node";
import { isBreakOrder } from "../../utilities";
import { useModal } from "../../context/Modal";
import { useSession } from "../../components/Session";

interface IBody {
  payload: {
    productId: number;
    variantId: number;
    quantity: number;
    adjust?: boolean;
  }[];
}

const OpenOrCloseRace = (props: {
  product_id: number | null;
  variant_id: number | null;
  session: ISessionWithBookings;
  loadSessions: () => void;
  view: TBookingType;
  getOrdersByVariantId: (variantId: number) => Promise<IOrder[]>;
}) => {
  const {
    variant_id,
    product_id,
    session,
    loadSessions,
    view,
    getOrdersByVariantId,
  } = props;
  const [releasingRaces, setReleasingRaces] = useState(false);
  const { setModalData, setshowModal } = useModal();
  const { getToken } = useSession();

  const isReleasedRace = useMemo(
    () => session.inventory_quantity > 0,
    [session]
  );

  const body = useMemo<IBody>(() => {
    if (product_id && variant_id) {
      return {
        payload: [
          isReleasedRace
            ? {
                productId: +product_id,
                variantId: +variant_id,
                quantity: 0,
                adjust: false,
              }
            : {
                productId: +product_id,
                variantId: +variant_id,
                quantity:
                  view === "MAIN_TRACK"
                    ? NUMBER_OF_SEATS_IN_A_BOOKING_MAIN_TRACK
                    : view === "MINI_TRACK"
                    ? NUMBER_OF_SEATS_IN_A_BOOKING_MINI_TRACK
                    : NUMBER_OF_SEATS_IN_A_VR,
                adjust: true,
              },
        ],
      };
    }
    return {
      payload: [],
    };
  }, [isReleasedRace, variant_id, product_id, view]);

  const callApi = useCallback(async () => {
    try {
      setReleasingRaces(true);
      const token = await getToken();

      await axios.post(
        // "http://localhost:5000/hyper-karting/us-central1/breakRace",
        "https://us-central1-hyper-karting.cloudfunctions.net/breakRace",
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await loadSessions();
    } catch (error) {
      throw error;
    } finally {
      setReleasingRaces(false);
    }
  }, [body, loadSessions, getToken]);

  const releaseRaceHandler = useCallback(async () => {
    setReleasingRaces(true);
    //TODO Prevent auto loading here

    try {
      //If we are closing race and have genuine order then take conformation
      if (isReleasedRace && variant_id) {
        const orders = await getOrdersByVariantId(variant_id);
        const genuineOrders = orders.filter((order) => !isBreakOrder(order));
        if (genuineOrders.length) {
          //TODO ask for conformation
          setModalData({
            id: "CLOSE_RACE_CONFIRMATION",
            onClose: () => setshowModal(false),
            title: "Close Race",
            content: (
              <ConformationModalContent
                onCancel={() => setshowModal(false)}
                callApi={callApi}
              />
            ),
          });
          setshowModal(true);
          return;
        }
      }
      // "http://localhost:5001/hyper-karting/us-central1/breakRace"||
      await callApi();
    } catch (error) {
      console.log("Error in releasing race", error);
    } finally {
      setReleasingRaces(false);
      //TODO allow auto loading here
    }
  }, [
    isReleasedRace,
    getOrdersByVariantId,
    callApi,
    setModalData,
    setshowModal,
    variant_id,
  ]);

  const buttonText = useMemo(() => {
    const entity =
      view === "VR"
        ? releasingRaces
          ? "Session..."
          : "Session"
        : releasingRaces
        ? "Race..."
        : "Race";
    const action = isReleasedRace
      ? releasingRaces
        ? "Closing"
        : "Close"
      : releasingRaces
      ? "Opening"
      : "Open";
    return `${action} ${entity}`;
  }, [releasingRaces, isReleasedRace, view]);

  return (
    <button
      onClick={releaseRaceHandler}
      className={`items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center ${
        isReleasedRace
          ? "bg-red-600 hover:bg-red-700"
          : "bg-green-500 hover:bg-green-700"
      } rounded-full`}
    >
      {buttonText}
    </button>
  );
};

const ConformationModalContent = ({
  onCancel,
  callApi,
}: {
  onCancel: () => void;
  callApi: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await callApi();
      onCancel();
    } catch (error) {
      console.log("Error in calling an release race api", error);
    } finally {
      setIsLoading(false);
    }
  }, [callApi, onCancel]);

  return (
    <div className="flex flex-col gap-1">
      <p className="my-4">
        There are races scheduled for this session. Would you like to close this
        race?
      </p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-red-500  hover:bg-red-700 rounded-full"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-green-500  hover:bg-green-700 rounded-full"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading ? "Sending..." : "Confirm"}
        </button>
      </div>
    </div>
  );
};
export default OpenOrCloseRace;

import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { TTransactionStatus } from "../../interfaces";
import { useModal } from "../../context/Modal";
import { useSession } from "../../components/Session";
import { IOrder } from "shopify-api-node";

const GoogleReviewMsg = (props: { order: IOrder; grmLog: any }) => {
  const { order, grmLog } = props;
  const { setModalData, setshowModal } = useModal();

  console.log("grmLog", grmLog);

  const status: TTransactionStatus | undefined = useMemo(
    () => grmLog?.status,
    [grmLog]
  );

  const showConfirmationPopup = useCallback(() => {
    setModalData({
      id: "GOOGLE_REVIEW_MSG_CONFIRMATION",
      onClose: () => setshowModal(false),
      title: "Send SMS for Google Review",
      content: (
        <ConformationModalContent
          onCancel={() => setshowModal(false)}
          order={order}
        />
      ),
    });
    setshowModal(true);
  }, [setModalData, setshowModal, order]);

  const buttonLabel = useMemo(() => {
    switch (status) {
      case "IN_PROGRESS":
        return "Sending";

      case "COMPLETED":
        return "Review SMS sent";

      case "FAILED":
      default:
        return "Review SMS";
    }
  }, [status]);

  const isSendBtnDisable = useMemo(
    () => status && status !== "FAILED",
    [status]
  );

  useEffect(() => {
    setshowModal(false);
  }, [isSendBtnDisable, setshowModal]);

  return (
    <>
      <button
        onClick={showConfirmationPopup}
        className={`items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center  rounded-full ${
          isSendBtnDisable
            ? "bg-gray-500  hover:bg-gray-700"
            : "bg-amber-500  hover:bg-amber-700"
        }`}
        disabled={isSendBtnDisable}
      >
        {buttonLabel}
      </button>
    </>
  );
};

function ConformationModalContent({
  onCancel,
  order,
}: {
  onCancel: () => void;
  order: IOrder;
}) {
  const [loading, setLoading] = useState(false);
  const { getToken } = useSession();

  const sendSms = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getToken();

      if (!token) {
        alert("User not loggeedin");
      }

      const { data } = await axios.post(
        // "http://localhost:5001/hyper-karting/us-central1/sendGoogleReviewMessage",
        "https://us-central1-hyper-karting.cloudfunctions.net/sendGoogleReviewMessage",
        {
          orderId: order.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      data.message && alert(data.message);
    } catch (error) {
      console.log("Error in send sms", error);
      alert(String(error));
    } finally {
      setLoading(false);
    }
  }, [order, getToken]);

  return (
    <div className="flex flex-col gap-1">
      <p className="mt-8 text-base">
        Are you sure you would like to send this customer a google review
        request?
      </p>
      <p className="mb-4 mt-2 text-xs font-bold">
        Note: Only send if there were no issues with their session
      </p>

      <hr className="mt-2" />
      <div className="flex justify-end gap-2 mt-5">
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-red-500  hover:bg-red-700 rounded-full"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="items-center px-2.5 py-1 border border-transparent text-xs font-medium shadow-sm text-white focus:outline-none w-max justify-center bg-green-500  hover:bg-green-700 rounded-full"
          onClick={sendSms}
          disabled={loading}
        >
          {loading ? "Sending..." : "Confirm"}
        </button>
      </div>
    </div>
  );
}

export default GoogleReviewMsg;

import app from "firebase/compat/app";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useFirebase } from "../Firebase";
import { HeaderLogo } from "./HeaderLogo";
import { IOrder } from "shopify-api-node";
import {
  IBooking,
  IBookingContext,
  IDriver,
} from "../../interfaces/BookingRegistration";
import { Loader } from "./Loader";

export const BookingContext = React.createContext<IBookingContext | null>(null);

interface Props {
  isGenericDriverRegistration?: boolean;
}

export default function BaseWrapper(props: Props) {
  const { isGenericDriverRegistration } = props;
  const firebase = useFirebase();
  const { db } = firebase;
  const { bookingId } = useParams();
  const [order, setOrder] = useState({} as IOrder);
  const [booking, setBooking] = useState({} as IBooking);
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<IDriver[] | []>([]);
  const [email, setEmail] = useState<string>("");
  const [isJunior, setIsJunior] = useState<boolean>(false);
  const [emailConsent, setEmailConsent] = useState<boolean>(false);

  const driverRef = useMemo(() => db.collection("drivers"), [db]);
  const bookingRef = useMemo(() => db.collection("bookings"), [db]);
  const orderRef = useMemo(() => db.collection("orders"), [db]);

  const refreshOrder = useCallback(async () => {
    const driver = await orderRef.doc(bookingId).get();
    if (driver.exists) {
      setOrder(driver.data());
    }
  }, [bookingId, orderRef]);

  const createOrUpdateDriver = async (id: string, doc: IDriver) => {
    //@TODO if exists then update otherwise create

    let docId = await checkDriverExistsByEmail(id);

    if (docId) {
      await driverRef.doc(docId).set(doc, { merge: true });
    } else {
      await driverRef.doc().set(doc);
    }

    const driver = await getDriver(id);
    return driver;
  };

  const checkDriverExistsByEmail = async (id: string) => {
    const driver = await driverRef.where("email", "==", id).get();
    if (driver?.docs?.length) {
      return Promise.resolve(driver.docs[0].id);
    } else {
      return Promise.resolve(null);
    }
  };

  const getDriver = async (id: string) => {
    const driver = await driverRef.where("email", "==", id).get();
    return (driver.docs?.[0]?.data() || {}) as IDriver;
  };

  const refreshBooking = useCallback(async () => {
    const booking = await bookingRef.doc(bookingId).get();

    if (booking.exists) {
      const ids = booking.data().drivers;
      const chunkSize = 10;
      const res = [];

      for (let i = 0; i < ids.length; i += chunkSize) {
        const chunk = ids.slice(i, i + chunkSize);
        res.push(chunk);
      }

      let driverData = await Promise.all(
        res.map((ids) =>
          driverRef
            .where(app.firestore.FieldPath.documentId(), "in", ids)
            ?.get()
        )
      );

      driverData = driverData.map((query) =>
        query?.docs?.map((doc: any) => doc?.data())
      );

      driverData = driverData.flat();
      driverData.sort((d1, d2) =>
        d1.first_name?.toLowerCase() > d2?.first_name?.toLowerCase() ? 1 : -1
      );

      setBooking({
        ...booking.data(),
        drivers: driverData,
      });
    }
    return null;
  }, [bookingId, bookingRef, driverRef]);

  const assignDriverToBooking = async (driver: IDriver) => {
    const driverDoc = await driverRef.where("email", "==", driver.email).get();
    const booking = await bookingRef.doc(bookingId).set(
      {
        orderId: bookingId,
        drivers: app.firestore.FieldValue.arrayUnion(driverDoc?.docs?.[0]?.id),
      },
      { merge: true }
    );
    return booking;
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await Promise.all([refreshOrder(), refreshBooking()]);
      setLoading(false);
    };
    loadData();
  }, [refreshOrder, refreshBooking]);

  //@TODO
  //This will fetch drivers using emailId from db as well as racefacer
  const refreshDrivers = async (id: string) => {
    // const driver = await driverRef.where("email", "==", id).get();
    // const driverList = driver.docs.map(
    //   (doc: QueryDocumentSnapshot) => doc.data() as IDriver
    // );
    // setDrivers(driverList);
    // return driverList;
    const getDriversFun = firebase.getCallableFunction(
      firebase.FUNCTION_NAMES.getDrivers
    );

    const drivers = (await getDriversFun({ email, isJunior })).data as {
      drivers: IDriver[];
    };
    setDrivers(drivers.drivers);
    return drivers.drivers;
  };

  return (
    <BookingContext.Provider
      value={{
        order,
        orderId: String(bookingId),
        refreshOrder,
        createOrUpdateDriver,
        checkDriverExistsByEmail,
        getDriver,
        refreshBooking,
        assignDriverToBooking,
        booking,
        setLoading,
        isGenericDriverRegistration: isGenericDriverRegistration || false,
        drivers,
        isJunior,
        email,
        setEmail,
        setIsJunior,
        emailConsent,
        setEmailConsent,
        refreshDrivers,
      }}
    >
      <div className={`h-full ${loading ? "overflow-hidden" : ""}`}>
        <HeaderLogo />
        <Outlet />
        {loading && <Loader />}
      </div>
    </BookingContext.Provider>
  );
}

import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";
import {
  EDifficulty,
  TArcadeGameCardSortOptions,
  TBookingType,
  TLogType,
} from "../../interfaces";

type TKey = TBookingType | "default" | TArcadeGameCardSortOptions | EDifficulty | TLogType;
interface IItem {
  key: TKey;
  label: string;
  icon?: ReactNode;
}

interface Props {
  items: IItem[];
  selected: TKey;
  className?: string;
  selectedBgColorCode?: string;
  onSelect?: (key: string) => void;
}

const getColor = (key: TKey, selectedBgColorCode = "#393B41") =>
  `${
    key === "MAIN_TRACK"
      ? "bg-[#F609FF]"
      : key === "MINI_TRACK"
      ? "bg-[#9D62FE]"
      : key === "VR"
      ? "bg-[#41BDFE]"
      : `bg-[${selectedBgColorCode}]`
  }`;
function DropDown({ items, selected, className, selectedBgColorCode, onSelect }: Props) {
  const [show, setShow] = useState(false);
  const selectedItem: IItem = useMemo(
    () =>
      items.find((item) => item.key === selected) || {
        key: "default",
        label: "Select",
      },
    [items, selected]
  );
  const nonSelectedItems: IItem[] = useMemo(
    () => items.filter((item) => item.key !== selected),
    [items, selected]
  );

  const toggleDropDown = useCallback(() => setShow((old) => !old), []);

  const handleSelect = useCallback(
    (key: TKey) => {
      onSelect && onSelect(key);
      setShow(false);
    },
    [onSelect]
  );

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShow(false);
      }}
    >
      <div className="relative h-full">
        <div
          className={`cursor-pointer h-12 flex items-center rounded-md font-bold text-base leading-6 text-white px-2 gap-4 w-max ${getColor(selectedItem.key, selectedBgColorCode)} ${className || ""}`}
          onClick={toggleDropDown}
        >
          {selectedItem.label}
          <ChevronDownIcon className="w-10 text-white" />
        </div>
        {show && (
          <div className="absolute left-0 right-0 top-14 rounded-md overflow-hidden z-10">
            {nonSelectedItems.map((item) => (
              <div
                key={item.key}
                className={`cursor-pointer font-bold text-base leading-6 text-white p-2 ${getColor(
                  "default"
                )}`}
                onClick={() => handleSelect(item.key)}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default DropDown;

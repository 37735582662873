import React, { useMemo } from 'react'
import { TLogDoc, IQuantityAdjustLog, IPriceChangerLog } from '../../interfaces/types'
import { TBookingType, TLogType, TObjFromArray } from '../../interfaces'
import { DateTime } from 'luxon'
import { getMaxNumberOfSession, getTrackDisplayName, getTrackFromSku } from '../../utilities'
import { IProduct, IProductVariant } from 'shopify-api-node'

const TRow = ({ logType, log, allLogs, products }: { log: TLogDoc, logType: TLogType, allLogs: TObjFromArray<TLogDoc>, products: IProduct[] }) => {
    if(logType === "QUANTITY_ADJUSTED" && isInstanceOf<IQuantityAdjustLog>(log, "QUANTITY_ADJUSTED")){
      const product = products.find(p=>p.id === log.productId);
      const variant = product?.variants.find(v=>v.id === log.variantId);
      const type = variant && getTrackFromSku(variant.sku);
      return (
        <tr>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-start flex-shrink-0 h-full">
              <SessionDetail variant={variant} type={type} log={log}/>
            </div>
          </td>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-center flex-shrink-0 h-full">
              {log.updatedQuantity === 0 ? (
                <div className="truncate text-white bg-red-500 p-1">Closed</div>
              ):(
                <div className="truncate text-white bg-green-500 p-1">Opened</div>
              )}
            </div>
          </td>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-center flex-shrink-0 h-full">
              <div className="truncate">{log.user.name}</div>
            </div>
          </td>
          <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex flex-col justify-center flex-shrink-0 h-full items-center">
              <p className="text-[#777]">
                {DateTime.fromMillis(log.ts).toFormat("dd LLL yyyy hh:mma")}
              </p>
            </div>
          </td>
        </tr>
      )
    }

    if(logType === "PRICE_CHANGER" && isInstanceOf<IPriceChangerLog>(log, "PRICE_CHANGER")){
        const sameVariantLogs = (Object.values(allLogs) as IPriceChangerLog[]).filter((l)=>l.variantId === log.variantId);
        const lastLog = getPreviousLog<IPriceChangerLog>(sameVariantLogs, log.ts);
        const product = products.find(p=>p.id === log.priductId);
        const variant = product?.variants.find(v=>v.id === log.variantId);
        const type = variant && getTrackFromSku(variant.sku);

        const updatedAfter = lastLog ? DateTime.fromMillis(log.ts).diff(DateTime.fromMillis(lastLog.ts), [ 'days', 'hours' ]).toHuman({ maximumFractionDigits: 0 }) : "";
        return (
          <tr>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
            <div className="flex items-center justify-start flex-shrink-0 h-full">
              <SessionDetail variant={variant} type={type} log={log}/>
            </div>
          </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{lastLog?.user.name}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{updatedAfter}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{log.user.name}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate"><del>{log.variant.price}</del> ↔︎ {log.updatedPrice}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{log.variant.inventory_quantity}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{(log.variant.inventory_quantity === 0) ? 0 : (getMaxNumberOfSession(getTrackFromSku(log.variant.sku)) - log.variant.inventory_quantity)}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex items-center justify-center flex-shrink-0 h-full">
                <div className="truncate">{log.variant.sku}</div>
              </div>
            </td>
            <td className="px-2 py-3 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
              <div className="flex flex-col justify-center flex-shrink-0 h-full items-center">
                <p className="text-[#777]">
                  {DateTime.fromMillis(log.ts).toFormat("dd LLL yyyy hh:mma")}
                </p>
              </div>
            </td>
          </tr>
        )
    }

    return (
        <></>
    )
}

const SessionDetail = ({variant, type, log}:{variant?: IProductVariant, type?:TBookingType, log:IQuantityAdjustLog | IPriceChangerLog})=>{
  const productId = useMemo(() => isInstanceOf<IQuantityAdjustLog>(log, "QUANTITY_ADJUSTED") ? log.productId : isInstanceOf<IPriceChangerLog>(log, "PRICE_CHANGER") ? log.priductId : null, [log])
  const options = useMemo(() => {
    if(!variant){
      return "";
    }
    if(type === "MAIN_TRACK"){
      return `${variant.option3} - ${variant.option1} - ${variant.option2}`
    }else if (type === "MINI_TRACK"){
      return `${variant.option1}`
    }else{
      return `${variant.option1} - ${variant.option2}`
    }
  }, [type, variant])
  
  return (
    <div className='flex flex-col justify-center items-start gap-1'>
      {(variant && type) && (
        <>
          <div className={`font-bold ${type === "MAIN_TRACK" ? "text-[#FF00FF]" : (type === "MINI_TRACK" ? "text-[#9D62FE]" : "text-[#41BDFE]")}`}>{getTrackDisplayName(type)}</div>
          <div>{options}</div>
          {/* <div>{getDatetimeFromSku(variant.sku, type)?.toFormat("hh:mma")}</div> */}
        </>
      )}
      <div><a href={`https://admin.shopify.com/store/hyper-karting/products/${productId}/variants/${log.variantId}`} target='_blank' rel="noreferrer" className='text-black underline'>View Session</a></div>
    </div>
  )
}

function isInstanceOf<T extends TLogDoc>(data: TLogDoc, type: TLogType): data is T {
    return data.type === type;
}

function getPreviousLog<T extends { ts: number }>(logs:T[], ts:number){
    if(logs.length <= 1){
        return null;
    }
    return logs.reduce((lastLog, log) => (log.ts > lastLog.ts && log.ts < ts)? log : lastLog, logs[0]);  // Assuming logs are sorted by timestamp in ascending order.  If not, use logs.sort((a, b) => a.ts - b.ts) before calling this function.  Also, if logs are empty or ts is less than the first log's ts, return undefined.  For now, we assume logs are always valid.  If not, add error handling here.  For example, return logs[0] if logs.length === 0.
}

export default TRow
import {
  CalendarIcon,
  DocumentReportIcon,
  FilmIcon,
  HomeIcon,
  UserIcon,
  UserGroupIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  DocumentTextIcon,
  ShieldExclamationIcon,
  FastForwardIcon,
  ClipboardIcon,
  BookmarkIcon,
} from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../constants";
import { useSession } from "../components/Session";
import { useMemo } from "react";

export interface IPrimaryNavItem {
  type: "primary";
  name: string;
  href: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
}
export interface ISecondaryNavItem {
  type: "secondary";
  name: string;
  childrens: IPrimaryNavItem[];
}
type INavItem = (IPrimaryNavItem | ISecondaryNavItem)[];

export default function useNavigation() {
  const location = useLocation();
  const { permissions } = useSession();
  const trackStaff = useMemo(() => permissions?.roles?.includes("TRACK_STAFF"), [permissions]);

  const hasSmsLogAccess = useMemo(() => permissions?.features?.includes("SMS_LOGS"), [permissions]);
  const hasEventDayTabAccess = useMemo(() => permissions?.features?.includes("EVENT_DAY_TAB"), [permissions]);

  const navigation: INavItem = [
    {
      type: "primary",
      name: "Dashboard",
      href: ROUTES.DASHBOARD,
      icon: HomeIcon,
      current: location.pathname === ROUTES.DASHBOARD,
    },
    {
      type: "primary",

      name: "Commission",
      href: ROUTES.COMMISSION_SCREEN,
      icon: DocumentReportIcon,
      current: location.pathname === ROUTES.COMMISSION_SCREEN,
    },
    {
      type: "primary",

      name: "Members",
      href: ROUTES.MEMBERS,
      icon: UserIcon,
      current: location.pathname === ROUTES.MEMBERS,
    },
    {
      type: "primary",

      name: "Calendar",
      href: ROUTES.CALENDAR,
      icon: CalendarIcon,
      current: location.pathname === ROUTES.CALENDAR,
    },

    {
      type: "primary",

      name: "Arcade Game Cards",
      href: ROUTES.ARCADE_CARDS,
      icon: CreditCardIcon,
      current: location.pathname === ROUTES.ARCADE_CARDS,
    },
    // { name: "Recent Orders", href: "#", icon: ClockIcon, current: false },
  ];

  if (!trackStaff) {
    navigation.push(
      ...([
        {
          type: "primary",
          name: "Pixel Wall",
          href: ROUTES.PIXEL_WALL,
          icon: FilmIcon,
          current: location.pathname === ROUTES.PIXEL_WALL,
        },
        {
          type: "primary",
          name: "Staff",
          href: ROUTES.STAFF,
          icon: UserGroupIcon,
          current: location.pathname === ROUTES.STAFF,
        },
        {
          type: "primary",
          name: "Price Changer",
          href: ROUTES.PRICE_CHANGER,
          icon: CurrencyDollarIcon,
          current: location.pathname === ROUTES.PRICE_CHANGER,
        },
      ] as INavItem)
    );
  }

  if(hasEventDayTabAccess){
    navigation.push({
      type: "primary",
      name: "Event Day",
      href: ROUTES.EVENTS_DAY,
      icon: BookmarkIcon,
      current: location.pathname === ROUTES.EVENTS_DAY,
    })
  }

  navigation.push(
    ...([
      {
        type: "primary",
        name: "VR Issues",
        href: ROUTES.ISSUE,
        icon: ShieldExclamationIcon,
        current: location.pathname === ROUTES.ISSUE,
      },
      {
        type: "primary",
        name: "Speed Control",
        href: ROUTES.SPEED_CONTROL,
        icon: FastForwardIcon,
        current: location.pathname === ROUTES.SPEED_CONTROL,
      },
      {
        type: "secondary",
        name: "Logs",
        childrens: [
          ...(hasSmsLogAccess
            ? [
                {
                  type: "primary",
                  name: "SMS Logs",
                  href: ROUTES.SMS_LOGS,
                  icon: DocumentTextIcon,
                  current: location.pathname === ROUTES.SMS_LOGS,
                },
              ]
            : []),
          {
            type: "primary",
            name: "Dashboard Logs",
            href: ROUTES.APP_LOGS,
            icon: DocumentTextIcon,
            current: location.pathname === ROUTES.APP_LOGS,
          },
          {
            type: "primary",
            name: "ALL Logs",
            href: ROUTES.LOGS,
            icon: ClipboardIcon,
            current: location.pathname === ROUTES.LOGS,
          },
        ],
      },
    ] as INavItem)
  );
  return navigation;
}

import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { IPriceRecommendation, TBookingType } from "../interfaces";
import axios from "axios";
import { useSession } from "../components/Session";

interface IItem {
  variantId: number;
  price: number;
}

export interface IPriceChangerContext {
  isUpdating: boolean;
  view: TBookingType;
  selectedDate: Date;
  changeView: (val: TBookingType) => void;
  changeSelectedDate: (val: Date) => void;
  toggleItem: (data: IItem) => void;
  isPriceSelected: (data: IItem) => boolean;
  isItemSelected: (variantId: number) => boolean;
  removeItemByVariant: (variantId: number) => void;
  clearItems: () => void;
  updateItems: (productId: number, cb: () => void) => void;
  recommendedPrices: IPriceRecommendation[];
  items: { [key: string]: number };
  getBgColor: (view: TBookingType) => string;
}

const PriceChangerContext = React.createContext<IPriceChangerContext>({
  isUpdating: false,
  view: "MAIN_TRACK",
  selectedDate: new Date(),
  changeView: (val: TBookingType) => {},
  changeSelectedDate: (val: Date) => {},
  toggleItem: (data: IItem) => {},
  isPriceSelected: (data: IItem) => false,
  isItemSelected: (variantId: number) => false,
  removeItemByVariant: (variantId: number) => {},
  clearItems: () => {},
  updateItems: (id: number, cb: () => void) => {},
  recommendedPrices: [],
  items: {},
  getBgColor: () => ""
});

interface Type {
  children: ReactNode;
}

const PriceChangerProvider: FC<Type> = ({ children }) => {
  const [view, setView] = useState<TBookingType>("MAIN_TRACK");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [items, setItems] = useState<{ [key: string]: number }>({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [recommendedPrices, setRecommendedPrices] = useState([])
  const { getToken } = useSession();

  const toggleItem = useCallback((data: IItem) => {
    setItems((_items) => {
      _items = JSON.parse(JSON.stringify(_items));
      if (_items[String(data.variantId)] === data.price) {
        delete _items[String(data.variantId)];
      } else {
        _items[String(data.variantId)] = data.price;
      }

      return _items;
    });
  }, []);

  const isPriceSelected = useCallback(
    (data: IItem) => {
      return items[String(data.variantId)] === data.price;
    },
    [items]
  );

  const isItemSelected = useCallback(
    (variantId: number) => {
      return Object.keys(items).includes(String(variantId));
    },
    [items]
  );

  const removeItemByVariant = useCallback((variantId: number) => {
    setItems((_items) => {
      _items = JSON.parse(JSON.stringify(_items));
      if (Object.keys(_items).includes(String(variantId))) {
        delete _items[String(variantId)];
      }

      return _items;
    });
  }, []);

  const clearItems = useCallback(() => {
    setItems({});
  }, []);

  const changeView = useCallback(
    (val: TBookingType) => {
      setView(val);
      clearItems();
    },
    [clearItems]
  );

  const changeSelectedDate = useCallback(
    (val: Date) => {
      setSelectedDate(val);
      clearItems();
    },
    [clearItems]
  );

  const getBgColor = useCallback((view: TBookingType) => view === "MAIN_TRACK" ? "bg-[#F609FF]" : (view === "MINI_TRACK" ? "bg-[#9D62FE]" : "bg-[#41BDFE]"),[])

  const updateItems = useCallback(
    async (productId: number, cb: () => void) => {
      try {
        const token = await getToken();
        if (!token) {
          return {
            status: false,
            message: "Token not generated",
          };
        }
        setIsUpdating(true);
        await axios.post(
          // "http://127.0.0.1:5001/hyper-karting/us-central1/priceChanger",
          "https://us-central1-hyper-karting.cloudfunctions.net/priceChanger",
          {
            payload: [
              {
                productId,
                variants: Object.keys(items).map((id) => ({
                  id: +id,
                  price: items[id],
                })),
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsUpdating(false);
        setItems({});
        cb();
      } catch (error) {
        setIsUpdating(false);
        return {
          status: false,
          message: error,
        };
      }
    },
    [items, getToken]
  );

  useEffect(() => {
    axios.get("https://us-central1-hyper-karting.cloudfunctions.net/recommendedVariantsPrice?ts="+selectedDate.getTime()).then(({data})=>{
      if(data.success){
        setRecommendedPrices(data.data)
      }
    }).catch(console.log)
  }, [selectedDate])
  

  return (
    <PriceChangerContext.Provider
      value={{
        isUpdating,
        changeSelectedDate,
        changeView,
        isItemSelected,
        isPriceSelected,
        selectedDate,
        toggleItem,
        view,
        removeItemByVariant,
        clearItems,
        updateItems,
        recommendedPrices,
        items,
        getBgColor
      }}
    >
      {children}
    </PriceChangerContext.Provider>
  );
};

export function usePricechanger() {
  return useContext(PriceChangerContext);
}

export default PriceChangerProvider;

import React, {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  FocusEventHandler,
} from "react";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import { BookingContext } from "./BaseWrapper";
import {
  ChevronDoubleRightIcon,
  PlusIcon,
  ChevronRightIcon,
  XIcon,
  UserAddIcon,
} from "@heroicons/react/solid";
import { IBookingContext, IDriver } from "../../interfaces/BookingRegistration";
import { DateTime } from "luxon";
import SignaturePad from "react-signature-canvas";
import { TValidationObject } from "../Types";
import { useToast } from "../../context/ToastContext";
import { ValidateEmail, ValidatePhone } from "../../utilities";
import { useFirebase } from "../Firebase";
import AdultLogo from "../../images/adult.svg";
import JuniorLogo from "../../images/junior.svg";
import { useNavigate } from "react-router-dom";

const NextStepIcon = (props: { active: boolean; clickHandler?: Function }) => {
  const { active, clickHandler } = props;
  return (
    <div
      className={`flex items-center text-gray-500 relative ${active ? "cursor-pointer" : "cursor-auto"
        }`}
      onClick={() => {
        if (!!clickHandler) {
          clickHandler();
        }
      }}
    >
      <div
        className={`rounded-full transition duration-500 ease-in-out h-14 w-14 py-3 border-2 border-white flex items-center justify-center ${active ? "bg-lime-500" : "bg-gray-300"
          }`}
      >
        <ChevronDoubleRightIcon
          className={`w-6 h-6 ${active ? "text-white" : "text-gray-700"}`}
        />
      </div>
    </div>
  );
};

interface HeaderProps {
  title: string;
  description?: string;
}

function Header(props: HeaderProps) {
  const { title, description } = props;
  return (
    <>
      <div className="text-black text-2xl font-semibold mb-2.5 sm:text-center">
        {title}
      </div>
      <div className="sm:mb-7 mb-5 font-normal text-base text-gray-500 sm:text-center">
        {description || ""}
      </div>
    </>
  );
}

function StepProgress(props: {
  firstHandler?: Function;
  secondHandler?: Function;
  thirdHandler?: Function;
}) {
  const { firstHandler, secondHandler, thirdHandler } = props;
  return (
    <>
      <div className="flex items-center mb-7">
        <NextStepIcon
          {...{ active: !!firstHandler, clickHandler: firstHandler }}
        />
        <div
          className={`flex-auto transition duration-500 ease-in-out ${!!secondHandler ? "bg-lime-500" : "bg-gray-300"
            } h-2.5`}
        ></div>
        <NextStepIcon
          {...{ active: !!secondHandler, clickHandler: secondHandler }}
        />
        <div
          className={`flex-auto transition duration-500 ease-in-out ${!!thirdHandler ? "bg-lime-500" : "bg-gray-300"
            } h-2.5`}
        ></div>
        <NextStepIcon
          {...{ active: !!thirdHandler, clickHandler: thirdHandler }}
        />
      </div>
    </>
  );
}

function EmailScreen(props: any) {
  const {
    goToNextPage,
    openScreen,
  }: { goToNextPage: Function; openScreen: Function } = props;
  const bookingContext = useContext(BookingContext);
  const {
    email,
    setEmail,
    isJunior,
    setIsJunior,
    refreshDrivers,
    setLoading,
    isGenericDriverRegistration,
  } = bookingContext as IBookingContext;

  console.log("isJunior", isJunior);


  const htmlButton = useRef<HTMLButtonElement>(null);
  const [errors, setErrors] = useState<any>({});

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      htmlButton.current?.click();
    }
  };

  const handleSubmit = async () => {
    if (!performValidation(email)) return;

    setLoading(true);
    await refreshDrivers(email);
    setLoading(false);
    goToNextPage();
  };

  const performValidation = (value: string) => {
    if (!ValidateEmail(value)) {
      setErrors({
        ...errors,
        email: "Email Id is invalid",
      });
      return false;
    } else {
      setErrors({
        ...errors,
        email: "",
      });
    }
    return true;
  };

  return (
    <>
      <Header
        title={
          isGenericDriverRegistration ? "Register Now" : "Add driver to race"
        }
        description="Create your profile with Hyper Karting To..."
      />
      <StepProgress
        firstHandler={() => openScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN)}
      />
      <div className="flex items-center justify-between sm:mb-7 mb-10 flex-wrap">
        <div
          className={`bg-white border-slate-${isJunior ? 200 : 400
            } border rounded-lg p-1 flex items-center sm:mb-0 mb-2.5 w-full sm:w-[calc(50%_-_10px)] cursor-pointer`}
          onClick={() => setIsJunior(false)}
        >
          <div className="rounded p-3 bg-zinc-200 mr-3.5">
            <img src={AdultLogo} alt="AdultLogo" />
          </div>
          <div>
            <div className="text-lg	mb-1 leading-5">Adult</div>
            <div className="text-xs	">Above 16 Year Old</div>
          </div>
        </div>
        <div
          className={`flex items-center bg-white border-slate-${isJunior ? 400 : 200
            } border rounded-lg p-1 w-full sm:w-[calc(50%_-_10px)] cursor-pointer`}
          onClick={() => setIsJunior(true)}
        >
          <div className="rounded p-3 bg-zinc-200 mr-3.5">
            <img src={JuniorLogo} alt="JuniorLogo" />
          </div>
          <div>
            <div className="text-lg	mb-1 leading-5">Child</div>
            <div className="text-xs	">Under 16 Years Old</div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <div className="text-base font-semibold	mb-2.5">
          Let's start with your email address:
        </div>
        <input
          type="text"
          name="email"
          placeholder="Email"
          className={`border rounded-lg py-3 px-4 outline-0 text-base text-slate-500 w-full ${errors?.email ? "border-red-500" : "border-slate-200"
            }`}
          value={email}
          onChange={(e) => {
            errors?.email && performValidation(e.target.value.toLowerCase());
            setEmail(e.target.value.toLowerCase());
          }}
          onKeyDown={handleKeypress}
        />
        {<p className="text-red-500 text-sm mt-1 ml-1">{errors?.email}</p>}
      </div>

      {/* <div className="flex items-center mb-10">
        <input
          type="checkbox"
          name="tcs_agreed"
          id="text"
          className="w-4 h-4 mr-2.5 cursor-pointer"
          checked={emailConsent}
          onChange={(e) => {
            setEmailConsent(e.target.checked);
          }}
        />
        <label
          htmlFor="text"
          className="text-slate-500 text-base leading-5 ̣font-normal"
        >
          Email Consent Text
        </label>
      </div> */}
      <button
        ref={htmlButton}
        className="text-center self-center items-center p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[335px] min-w-[100%]"
        onClick={handleSubmit}
      >
        Continue
      </button>
    </>
  );
}

function DriverListItem(props: {
  driver: IDriver;
  openDriverPreview: Function;
}) {
  const { driver, openDriverPreview } = props;
  const { first_name, last_name, dob } = driver;
  const age = Math.abs(
    Math.ceil(DateTime.fromFormat(dob, "yyyy-MM-dd").diffNow("years").years)
  );
  return (
    <>
      <div
        className="pt-3 pr-5 pl-4 pb-3 bg-white border border-slate-200 rounded-lg flex items-center justify-between hover:shadow mb-2.5 cursor-pointer"
        onClick={() => {
          openDriverPreview(driver);
        }}
      >
        <div className="flex items-center">
          {/* {driver?.avatar ? (
            <img
              className="w-[50px] h-[50px] rounded-full mr-2.5 flex items-center justify-center"
              src={driver.avatar}
              alt="driver.avatar"
            />
          ) : (
            <div className="w-[50px] h-[50px] bg-zinc-300 rounded-full mr-2.5 flex items-center justify-center" />
          )} */}
          <div>
            <div className="text-base leading-5 mb-[5px] sm:text-clip text-ellipsis overflow-hidden truncate sm:w-full w-[90px]">
              {`${first_name} ${last_name}`}
            </div>
            <div className="leading-4 text-sm">{age} Year Old</div>
          </div>
        </div>
        <div className="w-[24px] h-[24px] bg-[#5B36DF] rounded-full flex items-center justify-center">
          <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
        </div>
      </div>
    </>
  );
}

function DriverListScreen(props: any) {
  const {
    goToPreviousPage,
    openDriverPreview,
    openCreateDriverScreen,
  }: {
    goToPreviousPage: Function;
    openDriverPreview: Function;
    openCreateDriverScreen: Function;
  } = props;
  const bookingContext = useContext(BookingContext);
  const { drivers } = bookingContext as IBookingContext;
  useEffect(() => {
    if (!(drivers && drivers.length)) {
      openCreateDriverScreen();
    }
  }, [drivers, openCreateDriverScreen]);

  return (
    <>
      <Header title="Select Existing Profile" />
      {drivers.map((driver, i) => (
        <DriverListItem
          driver={driver}
          openDriverPreview={openDriverPreview}
          key={i}
        />
      ))}
      <div
        className="pt-3 pr-5 pl-4 pb-3 bg-white border border-slate-200 rounded-lg flex items-center justify-between hover:shadow mb-2.5 cursor-pointer"
        onClick={() => {
          openCreateDriverScreen();
        }}
      >
        <div className="flex items-center">
          <div className="w-[50px] h-[50px] bg-zinc-300 rounded-full mr-2.5 flex items-center justify-center">
            <PlusIcon fill="black" className="w-[24px] h-[24px]" />
          </div>
          <div>
            <div className="text-base leading-5">Create New User</div>
          </div>
        </div>
        <div className="">
          <div className="w-[24px] h-[24px] bg-[#5B36DF] rounded-full flex items-center justify-center">
            <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="text-center self-center w-full items-center p-2 border border-slate-200 text-medium font-semibold rounded shadow-sm. hover:bg-indigo-700 text-white bg-indigo-600"
          onClick={() => {
            goToPreviousPage();
          }}
        >
          Back
        </button>
        {/* <button
          className="text-center self-center items-center p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[calc(50%_-_10px)] min-w-[calc(50%_-_7px)]"
          onClick={() => {
            goToPreviousPage();
          }}
        >
          Next
        </button> */}
      </div>
    </>
  );
}

function DriverPreviewScreen(props: any) {
  const {
    driver,
    openEditDriverScreen,
    openScreen,
    openSuccessScreen,
  }: {
    driver: IDriver;
    openEditDriverScreen: Function;
    openScreen: Function;
    openSuccessScreen: Function;
  } = props;
  const firebase = useFirebase();
  const bookingContext = useContext(BookingContext);
  const { orderId, setLoading, isGenericDriverRegistration } =
    bookingContext as IBookingContext;

  const addDriverToRace = async (drivers: IDriver[], orderId: string) => {
    //save driver to db
    setLoading(true);
    const addDriverToBooking = firebase.getCallableFunction(
      firebase.FUNCTION_NAMES.addDriverToBooking
    );
    const res = await addDriverToBooking({ drivers: drivers, orderId });
    console.log("res", res);
    setLoading(false);
    openSuccessScreen();
  };

  return (
    <>
      <Header
        title={
          isGenericDriverRegistration ? "Register Now" : "Add driver to race"
        }
        description="Create your profile with Hyper Karting To..."
      />
      <StepProgress
        firstHandler={() => openScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN)}
        secondHandler={() => openScreen(SCREEN_NAMES.DRIVER_LIST)}
      />
      <div className="mb-5">
        <div className="text-base font-semibold	mb-2.5">
          Let's start with your email address:
        </div>
        <input
          type="text"
          name="email"
          className="border border-slate-200 rounded-lg py-3 px-4 outline-0 text-base text-slate-500 w-full"
          value={driver.email}
          disabled
        />
      </div>
      <div className="mb-3.5 text-gray-500 text-base font-normal">
        Looks like you have raced with us before, are these details correct?
      </div>
      <div className="outline-0 px-4	py-3 text-base border rounded-xl w-full resize-none mb-5">
        <div>{`${driver.first_name} ${driver.last_name}`}</div>
        <div>{driver.gender}</div>
        <div>{driver.dob}</div>
        <div>{driver.email}</div>
        <div>{driver.phone_number}</div>
        <div>{driver.address}</div>
      </div>
      {/* <div className="flex items-center mb-10">
        <input
          type="checkbox"
          name="tcs_agreed"
          id="waiver"
          className="w-4 h-4 mr-2.5 cursor-pointer"
        />
        <label
          htmlFor="waiver"
          className="text-slate-500 text-base leading-5 font-normal"
        >
          Waiver
        </label>
      </div> */}
      <div className="flex items-center justify-between flex-wrap mt-3.5">
        <button
          className="text-center self-center items-center p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[calc(49%_-_10px)] min-w-[100%]"
          onClick={() => {
            openEditDriverScreen(driver);
          }}
        >
          Edit Driver Details
        </button>
        {isGenericDriverRegistration ? (
          <button
            className="text-center self-center items-center sm:mt-0 mt-2.5 p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[calc(49%_-_10px)] min-w-[100%]"
            onClick={() => {
              openScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN);
            }}
          >
            Yes, these details are correct
          </button>
        ) : (
          <button
            className="text-center self-center items-center sm:mt-0 mt-2.5 p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[calc(49%_-_10px)] min-w-[100%]"
            onClick={() => {
              addDriverToRace([driver], orderId);
            }}
          >
            Add To Race
          </button>
        )}
      </div>
    </>
  );
}

function AdultDriverForm(props: {
  editMode: boolean;
  driver: IDriver;
  handleDriverFieldChange: Function;
  openSuccessScreen: Function;
}) {
  const { editMode, driver, handleDriverFieldChange, openSuccessScreen } =
    props;

  const toast = useToast();
  const firebase = useFirebase();
  const bookingContext = useContext(BookingContext);
  const {
    email,
    setLoading,
    refreshDrivers,
    orderId,
    isGenericDriverRegistration,
  } = bookingContext as IBookingContext;
  const addressRef = useRef<HTMLInputElement | null>(null);
  const [signatureRef, setSignatureRef] = useState({} as SignaturePad | null);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitDisable, setIsSubmitDisable] = useState<boolean>(true);

  const handleOnChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    switch (e.target.type) {
      case "checkbox":
        handleDriverFieldChange({ [e.target.name]: e.target.checked });
        break;
      default:
        let val = e.target.value;
        switch (e.target.name) {
          case "phone_number":
            val = !isNaN(+e.target.value)
              ? e.target.value
              : driver.phone_number;
            break;
          case "email":
            val = val.toLowerCase();
            break;
          default:
            break;
        }
        handleDriverFieldChange({ [e.target.name]: val });
        break;
    }
  };

  const handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement> = (e) => {
    handleDriverFieldChange({ [e.target.name]: e.target.value });
  };
  const handleBlurSelect: FocusEventHandler<HTMLSelectElement> = (e) => {
    const { hasError } = performValidation(e.target.name);
    if (!hasError) {
      removeErrorFromInputField(e.target.name);
    }
  };

  const removeErrorFromInputField = useCallback((key: string) => {
    setErrors((inputErrors: any) => ({
      ...inputErrors,
      [key]: "",
    }));
    // e?.classList?.remove?.("border-red-500");
  }, []);

  const handleBlurInput: FocusEventHandler<HTMLInputElement> = (e) => {
    const { hasError } = performValidation(e.target.name);
    if (!hasError) {
      removeErrorFromInputField(e.target.name);
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: "au" },
    },
    debounce: 300,
  });

  useEffect(() => {
    setIsSubmitDisable(!!Object.values(errors).filter((e) => e).length);
  }, [errors]);

  useEffect(() => {
    if (signatureRef?.fromDataURL && driver.signature_url) {
      signatureRef?.fromDataURL(driver.signature_url);
    }
  }, [signatureRef, driver.signature_url]);

  useEffect(() => {
    if (driver.email !== email) {
      handleDriverFieldChange({ email });
    }
  });

  useEffect(() => {
    setValue(driver.address, false);
  }, [driver.address, setValue]);

  const handleSelect = (value: any) => async () => {
    const { description, place_id: placeId } = value;

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    const results = (await getDetails({
      placeId,
    })) as google.maps.places.PlaceResult;

    const country = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes("country")
    );

    const zip_code = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes("postal_code")
    );

    const city = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes("administrative_area_level_2") ||
      addressComponent.types.includes("locality")
    );

    handleDriverFieldChange({
      address: description,
      country: country?.short_name,
      place_id: placeId,
      zip_code: zip_code?.long_name,
      city: city?.short_name || "unknown"
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="border-b	py-2.5 cursor-pointer last:border-0"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const clearSignature = () => {
    signatureRef?.clear();
    handleDriverFieldChange({ signature_url: "" });
  };

  const performValidation = useCallback(
    (key = "all") => {
      console.log("## validating for ", key);
      const validationObject: TValidationObject = {
        hasError: false,
        errors: [],
      };
      const addError = (msg: string | null, key: string) => {
        if (msg) {
          validationObject.hasError = true;
          validationObject.errors.push(msg);
        }
        setErrors((errors: any) => {
          let _errors = { ...errors };
          if (msg) {
            _errors = { ..._errors, [key]: msg };
          } else {
            delete _errors[key];
          }
          return _errors;
        });
      };

      if (key === "first_name" || key === "all") {
        console.log("driver.first_name", driver.first_name);

        if (!driver.first_name) {
          addError("Please enter first name", "first_name");
        } else {
          addError(null, "first_name");
        }
      }
      if (key === "last_name" || key === "all") {
        if (!driver.last_name) {
          addError("Please enter last name", "last_name");
        } else {
          addError(null, "last_name");
        }
      }
      if (key === "dob" || key === "all") {
        console.log("driver.dob", driver.dob);
        console.log(
          "datee",
          DateTime.fromFormat(driver.dob, "yyyy-MM-dd").toMillis() <
          DateTime.now().minus({ years: 16 }).toMillis()
        );

        if (!driver.dob) {
          addError("Please enter DOB", "dob");
        } else if (
          DateTime.fromFormat(driver.dob, "yyyy-MM-dd").toMillis() >
          DateTime.now().minus({ years: 16 }).toMillis()
        ) {
          addError(
            "Drivers under 16 must have a parent or gaurdian fill out this form",
            "dob"
          );
          toast.displayToast({
            message:
              "Drivers under 16 must have a parent or gaurdian fill out this form",
          });
        } else if (+DateTime.fromFormat(driver.dob, "yyyy-MM-dd").year < 1900) {
          addError("Enter valid date", "dob");
        } else {
          addError(null, "dob");
        }
      }
      if (key === "phone_number" || key === "all") {
        if (!driver.phone_number) {
          addError("Please enter phone number", "phone_number");
        } else {
          addError(null, "phone_number");
        }
      }
      if (key === "phone_number" || key === "all") {
        if (!ValidatePhone(driver.phone_number)) {
          addError("Phone no is not valid", "phone_number");
        } else {
          addError(null, "phone_number");
        }
      }
      if (key === "gender" || key === "all") {
        if (!driver.gender) {
          addError("Please select gender", "gender");
        } else {
          addError(null, "gender");
        }
      }
      if (key === "address" || key === "all") {
        console.log(driver?.address?.trim(), value?.trim());

        if (
          !driver.address ||
          driver?.address?.trim() !== value?.trim() ||
          !driver.country
        ) {
          addError("Please select valid address", "address");
        } else {
          addError(null, "address");
        }
      }
      if (key === "signature_url" || key === "all") {
        if (!driver.signature_url) {
          addError("Please add signature", "signature_url");
        } else {
          addError(null, "signature_url");
        }
      }
      if (key === "tcs_agreed" || key === "all") {
        if (!driver.tcs_agreed) {
          addError("Please accept terms and conditions", "tcs_agreed");
        } else {
          addError(null, "tcs_agreed");
        }
      }
      if (key === "risk_agreement_agreed" || key === "all") {
        if (!driver.risk_agreement_agreed) {
          addError("Please accept Risk agreement", "risk_agreement_agreed");
        } else {
          addError(null, "risk_agreement_agreed");
        }
      }

      return validationObject;
    },
    [driver, value, setErrors, toast]
  );

  useEffect(() => {
    const { hasError } = performValidation("address");

    if (!(hasError && driver.address !== value)) {
      removeErrorFromInputField("address");
    }
  }, [driver.address, value, removeErrorFromInputField, performValidation]);
  console.log("driver.address", driver.address);
  console.log("value", value);

  const handleSubmit = async () => {
    const { hasError, errors } = performValidation();
    console.log({ hasError, errors });
    if (hasError) {
      toast.displayToast({
        message: "Please fill required fields",
      });
      return;
    }

    const fun = firebase.getCallableFunction(
      editMode
        ? firebase.FUNCTION_NAMES.updateDrivers
        : firebase.FUNCTION_NAMES.createDrivers
    );

    const _driver = { ...driver };

    delete _driver.guardian_dob;
    delete _driver.guardian_first_name;
    delete _driver.guardian_last_name;

    setLoading(true);

    const { drivers } = (await fun({ drivers: [_driver] })).data as {
      drivers: IDriver[];
    };

    if (!isGenericDriverRegistration) {
      if (!editMode) {
        //save driver to db
        const addDriverToBooking = firebase.getCallableFunction(
          firebase.FUNCTION_NAMES.addDriverToBooking
        );
        const res = await addDriverToBooking({ drivers: drivers, orderId });
        console.log("res", res);
      }
      await refreshDrivers(email);
    }
    setLoading(false);
    openSuccessScreen();

    console.log("drivers", drivers);
  };

  return (
    <>
      <div className="sm:flex">
        <div className="mb-4 sm:mr-2.5 w-full">
          <span className="mb-2.5	text-base	font-semibold flex">First Name</span>
          <input
            name="first_name"
            onChange={handleOnChangeInput}
            onBlur={handleBlurInput}
            value={driver.first_name}
            type="text"
            placeholder="First Name"
            className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors.first_name && "border-red-500"
              }`}
          />
          {<p className="text-red-500 text-sm">{errors.first_name}</p>}
        </div>
        <div className="mb-4 sm:ml-2.5 w-full">
          <span className="mb-2.5	text-base	font-semibold flex">Last Name</span>
          <input
            type="text"
            name="last_name"
            onChange={handleOnChangeInput}
            onBlur={handleBlurInput}
            value={driver.last_name}
            placeholder="Last Name"
            className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors.last_name && "border-red-500"
              }`}
          />
          {<p className="text-red-500 text-sm">{errors.last_name}</p>}
        </div>
      </div>
      <div className="sm:flex">
        <div className="mb-4 sm:mr-2.5 w-full">
          <span className="mb-2.5	text-base	font-semibold flex">
            Date of Birth
          </span>
          <input
            type="date"
            name="dob"
            onChange={handleOnChangeInput}
            onBlur={handleBlurInput}
            value={driver.dob}
            min="1900-01-01"
            placeholder="DD-MM-YYYY"
            className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors.dob && "border-red-500"
              }`}
          />
          {<p className="text-red-500 text-sm">{errors.dob}</p>}
        </div>
        <div className="mb-4 sm:ml-2.5 w-full">
          <span className="mb-2.5 text-base	font-semibold flex">
            Mobile Phone
          </span>
          <input
            type="text"
            name="phone_number"
            onChange={handleOnChangeInput}
            onBlur={handleBlurInput}
            value={driver.phone_number}
            placeholder="1234567890"
            className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors.phone_number && "border-red-500"
              }`}
          />
          {<p className="text-red-500 text-sm">{errors.phone_number}</p>}
        </div>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="mb-4 sm:mr-2.5 w-full">
          <span className="mb-2.5	text-base	font-semibold flex">Gender</span>
          <select
            name="gender"
            onChange={handleOnChangeSelect}
            onBlur={handleBlurSelect}
            value={driver.gender ?? "select"}
            className={`outline-0 px-4	py-3 text-base border rounded-xl w-full appearance-none ${errors.gender && "border-red-500"
              }`}
          >
            <option value="select" disabled>
              Select
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="non-disclose">Prefer not to disclose</option>
          </select>
          {<p className="text-red-500 text-sm">{errors.gender}</p>}
        </div>
      </div>
      <div className="mb-5 relative">
        <span className="mb-2.5 text-base font-semibold flex">Address</span>
        <input
          autoComplete="off"
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          // onBlur={onBlurValidateHandler("address")}
          value={value}
          disabled={!ready}
          placeholder="Address"
          name="address"
          className={`outline-0 px-4 py-3 text-base border rounded-xl w-full ${errors.address && "border-red-500"
            }`}
          ref={addressRef}
          onBlur={handleBlurInput}
        />
        {<p className="text-red-500 text-sm">{errors.address}</p>}
        {status === "OK" && (
          <ul className="absolute bg-white border rounded-xl py-0.5 p-3 top-full	max-h-[350px] overflow-auto	z-[999] w-full shadow-lg">
            {renderSuggestions()}
          </ul>
        )}
      </div>
      <div className="text-xl	mb-3 font-semibold">Terms and Conditions</div>
      <div className="flex mb-2.5">
        <div className="w-2.5 h-2.5 mr-2.5 mt-1.5">
          <div className="bg-indigo-600 w-2.5 h-2.5 rounded-full"></div>
        </div>
        <p className="font-normal	text-sm	uppercase">
          To Participate in the activity of karting you must read and sign the
          following:
        </p>
      </div>
      <div className="w-full shadow-inner px-4 py-3 mb-4 text-sm max-h-56 overflow-y-scroll">
        <p>
          <span className="underline">Risk Warning and Acknowledgement</span>
          <br />
          I am the participant named above and in consideration of Hyper Karting
          Pty Ltd (“the Provider”) providing services and/or equipment to enable
          me to participate in Karting (“the Activity”), I agree as follows:
          <br />
          1. I will comply with all rules and directions provided by the
          Provider and their employees including, but not limited to:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) Reading and complying with all safety
          instructions and signs in the kiosk and at the entrance of the
          Activity prior to my participation; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) complying with all verbal and/or video
          instructions and directions by employees of the Provider.
          <br />
          2. I understand, acknowledge and agree that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) I will exercise due care and skill while
          participating in the Activity;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) the Activity is dangerous and may result
          in serious injury, and my participation in the Activity and my use of
          any equipment related to the Activity involves inherent risks that
          will sometimes occur; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (c) the Activity requires physical fitness,
          skill and mental alertness; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (d) my participation in the Activity and my
          use of such equipment may result in my injury or illness including but
          not limited to bodily injury, disease, strains, fractures, partial
          and/or total paralysis, eye injury, blindness, heat stroke, heart
          attack, death or other ailments that could cause serious disability;{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (e) these risks and dangers may be caused by
          the negligence of the owners, employees, officers or agents of the
          Provider, the negligence of the participants, the negligence of
          others, accidents, breaches of contract the forces of nature or other
          causes. These risks and dangers may arise from foreseeable or
          unforeseeable causes;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (f) the Provider has not made any
          representations in respect to the adequacy of my skills, level of
          physical or mental fitness, psychological state or any other capacity
          in relation to my participation in the Activity; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (g) I have made or will make full disclosure
          in writing to the Provider about health issues or other relevant
          matters of any kind concerning me and which might reasonably be
          expected to impact upon my ability to participate in the Activity; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (h) I participate in the Activity solely at
          my own risk.
          <br />
          3. I further understand, acknowledge and agree that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) the Go Karts travel at high speeds and
          are dangerous;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) there is no insurance coverage for kart
          collisions or injury I may cause to another participant; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (c) participation is prohibited for drivers
          who have consumed any alcohol or illicit substances.
          <br />
          4. I release and hold harmless the Provider, and all associated
          entities of the Provider, including but not limited to Hyper Karting
          Pty Ltd, and their respective shareholders, directors, officers,
          employees and agents from all liabilities arising from my
          participation in the Activity and from my use of any equipment
          provided by the Provider including, but not limited to, any and all
          claims, actions, demands or losses for bodily injury, death, and loss
          or damage to property which I may have now or at any time in the
          future (including where such claims, actions, demands or losses arise
          from any negligent act or omission to act by the Providers).
          <br />
          5. By signing this document, I also acknowledge, agree, and understand
          that the risk warning above constitutes a 'risk warning' for the
          purposes of the relevant legislation, including for the purpose of
          section 5M of the Civil Liability Act 2002 (NSW)
          <br />
          6. I agree to indemnify, defend and hold harmless the Provider, and
          all associated entities of the Provider, including but not limited to
          Hyper Karting Pty Ltd, and their shareholders, directors, officers,
          employees and agents from any and all third party claims, liability,
          damages of every kind of nature whether known or unknown and/or costs
          (including, but not limited to, legal costs) arising from or in any
          way related to my participation in the Activity and my use of any
          equipment provided by the Providers.
          <br />
          7. I request and agree that first aid or other appropriate medical
          treatment be administered to me in such manner as the Provider or any
          of its officers or employees or agents shall deem necessary. In such
          an event, I agree to indemnify, hold harmless and forever discharge
          the parties listed above for all injury or damage incurred in the
          course of administering the First Aid.
          <br />
          8. I hereby consent to the Provider to take photographs and or video
          footage where I may feature and distribute it on any medium including
          but not limited to print and online. I understand I will not be paid
          for giving this consent and I hereby waive any right to remuneration
          or any fee in respect to its use. <br />
        </p>
        <h4>WARNING UNDER THE AUSTRALIAN CONSUMER LAW</h4>
        <p>
          Under the Australian Consumer Law (New South Wales), several statutory
          guarantees apply to the supply of certain goods and services. These
          guarantees mean that the supplier named on this form is required to
          ensure that the recreational services it supplies to you— <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• are rendered with due care and skill; and{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• are reasonably fit for any purpose which
          you, either expressly or by implication, make known to the supplier;
          and <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• might reasonably be expected to achieve any
          result you have made known to the supplier. <br />
          1. Section 139A of Competition and Consumer Act 2010 (Cth) permits the
          Provider of the Recreational Activities and associated services to ask
          you to agree that the statutory guarantees under the Australian
          Consumer Law (Cth) do not apply to you (or a person for whom or on
          whose behalf you are acquiring the services <br />
          2. By signing this document, you acknowledge, agree and understand
          that, to the full extent permitted by law, the liability of the
          Provider in relation to recreational services (as that term is defined
          in the Australian Consumer Law (Cth) and any similar state laws) and
          recreational activities (as that term is defined in the Civil
          Liability Act 2002 (NSW)) for any: <br />
          &nbsp;&nbsp;&nbsp;&nbsp; a. death; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; b. physical or mental injury (including the
          aggravation, acceleration or recurrence of such an injury); <br />
          &nbsp;&nbsp;&nbsp;&nbsp; c. the contraction, aggravation or
          acceleration of a disease; d. the coming into existence, the
          aggravation, acceleration or recurrence of any other condition,
          circumstance, occurrence, activity, form of behaviour, course of
          conduct or state of affairs: <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i. that is or may be
          harmful or disadvantageous to you or the community; <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii. that may result
          in harm or disadvantage to you or the community; <br />
          that may be suffered by you (or a person for whom or on whose behalf
          you are acquiring the services) resulting from the supply of
          recreational services or recreational activities is excluded. <br />
          3. By signing this document, to the full extent permitted by law, you
          (or the person for whom or on whose behalf you are acquiring the
          services) agree to waive and/or release the Provider, its servants and
          agents, from any claim, right or cause of action which you or your
          heirs, successors, executors, administrators, agents and assigns might
          otherwise have against the Provider, its servant and agents, for or
          arising out of your death or physical or mental injury, disease, loss
          and damage, or economic loss of any description whatsoever which you
          may suffer or sustain in the course of or consequential upon or
          incidental to your participation in the Activity, whether caused by
          the negligence of the Provider, its servant and agents, or otherwise.
          <br />
          4. You do not have to agree to exclude, restrict or modify or waive
          your rights against, or release, the Service Provider, its servants
          and agents, from any claims by signing this document, however the
          Service Provider may refuse to allow you to participate in the
          Recreational Activities, or to provide you with the associated
          services, if you do not agree to exclude, restrict, modify or waive
          your rights against, or release, the Service Provider, its servants
          and agents, by signing this document.
        </p>

        <p></p>
        <h4>
          For participants under 18 years of age:
          <br />
          (required for all underage participants)
        </h4>
        <p>
          I hereby: <br />
          1. confirm that I am over the age of eighteen years and that I am the
          guardian of the participant; <br />
          2. acknowledge that I have read and clearly understand each of the
          conditions hereof;
          <br />
          3. warrant and declare that the information set out in this document
          is true and correct in every particular;
          <br />
          4. acknowledge that I accept full liability and responsibility for the
          actions of the participant during their use of the activity facilities
          and will ensure that they comply with the above terms and conditions;
          and
          <br />
          5. indemnify and will keep indemnified the Provider and all associated
          entities of the Provider, including but not limited to Hyper Karting
          Pty Ltd and its officers, agents and personnel from all actions,
          claims, demands or proceedings made by or on behalf of the participant
          or any third party acting on behalf of the participant.
        </p>
      </div>
      <div className="text-xl	mb-3 font-semibold text-left">
        Please Sign Here
      </div>
      <div className="h-[200px]">
        <SignaturePad
          penColor="black"
          clearOnResize={false}
          canvasProps={{
            className:
              "sigCanvas outline-0 text-base border rounded-xl resize-none mb-5 w-full h-full",
          }}
          ref={(ref) => setSignatureRef(ref)}
          onEnd={() => {
            handleDriverFieldChange({
              signature_url: signatureRef?.toDataURL(),
            });
          }}
        />
      </div>

      {/* {<p className="text-red-500 text-sm">{errors.signature_url}</p>} */}
      <div className="mb-4 flex" onClick={clearSignature}>
        {<p className="text-red-500 text-sm">{errors.signature_url}</p>}
        <div className="text-sm underline text-slate-500 pr-1 cursor-pointer w-max ml-auto">
          Clear
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="tcs_agreed"
          onChange={handleOnChangeInput}
          onBlur={handleBlurInput}
          checked={driver.tcs_agreed}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer"
          id="tcs_agreed"
        />
        <label
          htmlFor="tcs_agreed"
          className="text-slate-500 text-sm font-normal sm:max-w-[calc(100%_-_1.625rem)] max-w-[calc(100%_-_2.625rem)]"
        >
          I agree with both Hyper Karting and RaceFacers' Terms & Conditions,
          Privacy Policy and Cookies Policy.
        </label>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="risk_agreement_agreed"
          onChange={handleOnChangeInput}
          onBlur={handleBlurInput}
          checked={driver.risk_agreement_agreed}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer"
          id="risk_agreement_agreed"
        />
        <label
          htmlFor="risk_agreement_agreed"
          className="text-slate-500 text-sm font-normal max-w-[calc(100%_-_2.625rem)] sm:max-w-[calc(100%_-_1.625rem)]"
        >
          I understand that Karting is an inherently dangerous activity which
          may cause serious injury or death and I am participating at my own
          risk.
        </label>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="email_consent"
          onChange={handleOnChangeInput}
          onBlur={handleBlurInput}
          checked={driver.email_consent}
          className="sm:w-4 sm:h-4 w-[18px] h-[18px] mr-2.5 cursor-pointer"
          id="email_consent"
        />
        <label
          htmlFor="email_consent"
          className="text-slate-500 text-sm font-normal max-w-[calc(100%_-_2.625rem)] sm:max-w-[calc(100%_-_1.625rem)]"
        >
          I agree to receive email & sms notifications from Hyper Karting
          regarding: Discount Codes, Promotional Offers & Newsletter.
        </label>
      </div>

      <p className="text-red-500 text-sm">
        {errors.tcs_agreed || errors.risk_agreement_agreed}
      </p>

      <button
        className={`text-center self-center items-center mt-10 p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[335px] min-w-[100%] ${isSubmitDisable && "bg-gray-600 hover:bg-gray-700"
          }`}
        onClick={() => {
          handleSubmit();
        }}
      >
        {editMode ? "Update Profile" : "Create and Add driver"}
      </button>
    </>
  );
}

function JuniorFormItem(props: {
  editMode: boolean;
  driver: IDriver;
  handleOnChangeInput: ChangeEventHandler<HTMLInputElement>;
  handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement>;
  removeJunior: Function;
  index: number;
  performValidation: Function;
  removeErrorFromInputField: Function;
  errors: any;
}) {
  const {
    driver,
    handleOnChangeInput,
    handleOnChangeSelect,
    index,
    removeJunior,
    performValidation,
    removeErrorFromInputField,
    errors,
  } = props;

  const handleBlurSelect: FocusEventHandler<HTMLSelectElement> = (e) => {
    const { hasError } = performValidation(e.target.name, index);
    if (!hasError) {
      removeErrorFromInputField(e.target.name);
    }
  };
  const handleBlurInput: FocusEventHandler<HTMLInputElement> = (e) => {
    const { hasError } = performValidation(e.target.name, index);
    if (!hasError) {
      removeErrorFromInputField(e.target.name);
    }
  };
  return (
    <div className="border rounded-[10px] border-[#e5e5e5] overflow-hidden mt-[30px]">
      <div className="bg-[#F7F5FE] py-[15px] px-[10px] flex items-center justify-between">
        <span className="text-base leading-5">Child Details:</span>
        <span className="w-[19px] h-[19px] cursor-pointer">
          {index ? (
            <XIcon
              onClick={() => {
                removeJunior(index);
              }}
            />
          ) : (
            <></>
          )}
        </span>
      </div>
      <div className="px-[20px] pb-[20px]">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
            <label className="text-base	leading-5 mb-[10px]">First Name</label>
            <input
              name="first_name"
              onChange={handleOnChangeInput}
              value={driver.first_name}
              type="text"
              placeholder="First Name"
              className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors[`first_name${index}`] && "border-red-500"
                }`}
              data-index={index}
              onBlur={handleBlurInput}
            />
            <p className="text-red-500 text-sm">
              {errors[`first_name${index}`]}
            </p>
          </div>
          <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
            <label className="text-base	leading-5 mb-[10px]">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleOnChangeInput}
              value={driver.last_name}
              placeholder="Last Name"
              className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors[`last_name${index}`] && "border-red-500"
                }`}
              data-index={index}
              onBlur={handleBlurInput}
            />
            <p className="text-red-500 text-sm">
              {errors[`last_name${index}`]}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
            <label className="text-base	leading-5 mb-[10px]">
              Date of Birth
            </label>
            <input
              type="date"
              name="dob"
              onChange={handleOnChangeInput}
              value={driver.dob}
              placeholder="DD-MM-YYYY"
              className={`outline-0 px-4	py-3 text-base border rounded-xl w-full ${errors[`dob${index}`] && "border-red-500"
                }`}
              data-index={index}
              onBlur={handleBlurInput}
            />
            <p className="text-red-500 text-sm">{errors[`dob${index}`]}</p>
          </div>
          <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
            <label className="text-base	leading-5 mb-[10px]">Gender</label>
            <select
              name="gender"
              onChange={handleOnChangeSelect}
              value={driver.gender ?? "select"}
              className={`outline-0 px-4	py-3 text-base border rounded-xl w-full appearance-none ${errors[`gender${index}`] && "border-red-500"
                }`}
              data-index={index}
              onBlur={handleBlurSelect}
            >
              <option value="select" disabled>
                Select
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-disclose">Prefer not to disclose</option>
            </select>
            <p className="text-red-500 text-sm">{errors[`gender${index}`]}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function JuniorDriverForm(props: any) {
  const {
    editMode,
    driver,
    handleDriverFieldChange,
    openSuccessScreen,
  }: {
    editMode: boolean;
    driver: IDriver;
    handleDriverFieldChange: Function;
    openSuccessScreen: Function;
  } = props;

  const toast = useToast();
  const firebase = useFirebase();
  const bookingContext = useContext(BookingContext);
  const {
    email,
    setLoading,
    refreshDrivers,
    orderId,
    isGenericDriverRegistration,
  } = bookingContext as IBookingContext;
  const addressRef = useRef<HTMLInputElement | null>(null);
  const [signatureRef, setSignatureRef] = useState({} as SignaturePad | null);
  const [juniors, setJuniors] = useState<IDriver[]>([driver]);

  const [errors, setErrors] = useState<any>({});
  const [isSubmitDisable, setIsSubmitDisable] = useState<boolean>(true);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: "au" },
    },
    debounce: 300,
  });

  useEffect(() => {
    setIsSubmitDisable(!!Object.values(errors).filter((e) => e).length);
  }, [errors]);
  const performValidation = useCallback(
    (key = "all", index?: number) => {
      if (index !== undefined) {
        key = key + index;
      }
      console.log("## validating for ", key);
      const validationObject: TValidationObject = {
        hasError: false,
        errors: [],
      };
      const addError = (msg: string | null, key: string) => {
        console.log("setting", { msg, key });

        if (msg) {
          validationObject.hasError = true;
          validationObject.errors.push(msg);
        }
        setErrors((errors: any) => {
          let _errors = { ...errors };
          if (msg) {
            _errors = { ..._errors, [key]: msg };
          } else {
            delete _errors[key];
          }
          return _errors;
        });
      };

      console.log(juniors);

      if (index !== undefined || key === "all") {
        if (index === undefined) {
          console.log("in ifff");

          for (let i = 0; i < juniors.length; i++) {
            console.log("executing i ", i);

            if (key.startsWith("first_name") || key === "all") {
              if (!juniors[i].first_name) {
                addError("Please enter first name", "first_name" + i);
              } else {
                addError(null, "first_name" + i);
              }
            }
            if (key.startsWith("last_name") || key === "all") {
              if (!juniors[i].last_name) {
                addError("Please enter last name", "last_name" + i);
              } else {
                addError(null, "last_name" + i);
              }
            }
            if (key.startsWith("dob") || key === "all") {
              if (!juniors[i].dob) {
                addError("Please enter DOB", "dob" + i);
              } else {
                addError(null, "dob" + i);
              }
            }
            if (key.startsWith("gender") || key === "all") {
              if (!juniors[i].gender) {
                addError("Please enter gender", "gender" + i);
              } else {
                addError(null, "gender" + i);
              }
            }
          }
        } else {
          console.log("index", index);
          if (key.startsWith("first_name") || key === "all") {
            if (!juniors[index].first_name) {
              addError("Please enter first name", key);
            } else {
              addError(null, key);
            }
          }
          if (key.startsWith("last_name") || key === "all") {
            if (!juniors[index].last_name) {
              addError("Please enter last name", key);
            } else {
              addError(null, key);
            }
          }
          if (key.startsWith("dob") || key === "all") {
            if (!juniors[index].dob) {
              addError("Please enter DOB", key);
            } else {
              addError(null, key);
            }
          }
          if (key.startsWith("gender") || key === "all") {
            if (!juniors[index].gender) {
              addError("Please enter gender", key);
            } else {
              addError(null, key);
            }
          }
        }
      }

      if (key === "guardian_first_name" || key === "all") {
        if (!driver.guardian_first_name) {
          addError("Please enter first name", "guardian_first_name");
        } else {
          addError(null, "guardian_first_name");
        }
      }
      if (key === "guardian_last_name" || key === "all") {
        if (!driver.guardian_last_name) {
          addError("Please enter last name", "guardian_last_name");
        } else {
          addError(null, "guardian_last_name");
        }
      }
      if (key === "guardian_dob" || key === "all") {
        console.log("driver.guardian_dob", driver.guardian_dob);

        if (!driver.guardian_dob) {
          addError("Please enter DOB", "dob");
        } else {
          addError(null, "guardian_dob");
        }
      }
      if (key === "phone_number" || key === "all") {
        if (!driver.phone_number) {
          addError("Please enter phone number", "phone_number");
        } else {
          addError(null, "phone_number");
        }
      }
      if (key === "phone_number" || key === "all") {
        if (!ValidatePhone(driver.phone_number)) {
          addError("Phone no is not valid", "phone_number");
        } else {
          addError(null, "phone_number");
        }
      }
      if (key === "gender" || key === "all") {
        if (!driver.gender) {
          addError("Please select gender", "gender");
        } else {
          addError(null, "gender");
        }
      }
      if (key === "address" || key === "all") {
        console.log(driver?.address?.trim(), value?.trim());

        if (
          !driver.address ||
          driver?.address?.trim() !== value?.trim() ||
          !driver.country
        ) {
          addError("Please select valid address", "address");
        } else {
          addError(null, "address");
        }
      }

      console.log(key, driver.signature_url);

      if (key === "signature_url" || key === "all") {
        if (!driver.signature_url) {
          console.log("in if");

          addError("Please add signature", "signature_url");
        } else {
          console.log("in else");

          addError(null, "signature_url");
        }
      }
      if (key === "tcs_agreed" || key === "all") {
        if (!driver.tcs_agreed) {
          addError("Please accept terms and conditions", "tcs_agreed");
        } else {
          addError(null, "tcs_agreed");
        }
      }
      if (key === "risk_agreement_agreed" || key === "all") {
        if (!driver.risk_agreement_agreed) {
          addError("Please accept Risk agreement", "risk_agreement_agreed");
        } else {
          addError(null, "risk_agreement_agreed");
        }
      }

      return validationObject;
    },
    [driver, value, setErrors, juniors]
  );

  console.table(errors);

  const handleOnChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (["first_name", "last_name", "dob", "gender"].includes(e.target.name)) {
      const index = +(e?.target?.dataset?.index || 0);
      setJuniors((juniors) => {
        juniors = [...juniors];
        const junior = { ...juniors[index], [e.target.name]: e.target.value };
        juniors[index] = junior;
        return juniors;
      });
    } else {
      switch (e.target.type) {
        case "checkbox":
          handleDriverFieldChange({ [e.target.name]: e.target.checked });
          break;
        default:
          let val = e.target.value;
          switch (e.target.name) {
            case "phone_number":
              val = !isNaN(+e.target.value)
                ? e.target.value
                : driver.phone_number;
              break;
            case "email":
              val = val.toLowerCase();
              break;
            default:
              break;
          }
          handleDriverFieldChange({ [e.target.name]: val });
          break;
      }
    }
  };
  const handleOnChangeSelect: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (["first_name", "last_name", "dob", "gender"].includes(e.target.name)) {
      const index = +(e?.target?.dataset?.index || 0);
      setJuniors((juniors) => {
        juniors = [...juniors];
        const junior = { ...juniors[index], [e.target.name]: e.target.value };
        juniors[index] = junior;
        return juniors;
      });
    } else {
      handleDriverFieldChange({ [e.target.name]: e.target.value });
    }
  };

  const removeErrorFromInputField = useCallback((key: string) => {
    setErrors((inputErrors: any) => ({
      ...inputErrors,
      [key]: "",
    }));
    // e?.classList?.remove?.("border-red-500");
  }, []);

  const handleBlurInput: FocusEventHandler<HTMLInputElement> = (e) => {
    const { hasError } = performValidation(e.target.name);
    if (!hasError) {
      removeErrorFromInputField(e.target.name);
    }
  };

  useEffect(() => {
    const { hasError } = performValidation("address");

    if (!(hasError && driver.address !== value)) {
      removeErrorFromInputField("address");
    }
  }, [driver.address, value, removeErrorFromInputField, performValidation]);

  useEffect(() => {
    if (signatureRef?.fromDataURL && driver.signature_url) {
      signatureRef?.fromDataURL(driver.signature_url);
    }
  }, [signatureRef, driver.signature_url]);

  useEffect(() => {
    handleDriverFieldChange({ isJunior: true, email });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue(driver.address, false);
  }, [driver.address, setValue]);

  useEffect(() => {
    if (editMode) {
      setJuniors([{ ...driver }]);
    }
  }, [editMode, setJuniors, driver]);

  const handleSelect = (value: any) => async () => {
    const { description, place_id: placeId } = value;

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    const results = (await getDetails({
      placeId,
    })) as google.maps.places.PlaceResult;

    const country = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes("country")
    );

    const zip_code = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes("postal_code")
    );

    const city = results.address_components?.find((addressComponent: any) =>
      addressComponent.types.includes("administrative_area_level_2") ||
      addressComponent.types.includes("locality")
    );

    handleDriverFieldChange({
      address: description,
      country: country?.short_name,
      place_id: placeId,
      zip_code: zip_code?.long_name,
      city: city?.short_name || "unknown"
    });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="border-b	py-2.5 cursor-pointer last:border-0"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const clearSignature = () => {
    signatureRef?.clear();
    handleDriverFieldChange({ signature_url: "" });
  };

  const addNewJunior = () => {
    setJuniors((juniors) => [...juniors, { ...defaultDriverData } as IDriver]);
  };
  const removeJunior = (index: number) => {
    setJuniors((juniors) => [
      ...juniors.slice(0, index),
      ...juniors.slice(index + 1),
    ]);
    setErrors((errors: any) => {
      let _errors = { ...errors };
      ["first_name", "last_name", "dob", "gender"].forEach((key) => {
        Object.keys(errors)
          .filter((e) => e.startsWith(key) && e !== key)
          .forEach((val) => {
            delete _errors[val];
          });
      });
      return _errors;
    });
  };

  const handleSubmit = async () => {
    const { hasError, errors } = performValidation();
    console.log({ hasError, errors });
    if (hasError) {
      toast.displayToast({
        message: "Please fill required fields",
      });
      return;
    }

    const _drivers = [
      ...juniors.map((junior) => ({
        ...driver,
        first_name: junior.first_name,
        last_name: junior.last_name,
        dob: junior.dob,
        gender: junior.gender,
      })),
    ];

    console.log("_drivers", _drivers);

    const fun = firebase.getCallableFunction(
      editMode
        ? firebase.FUNCTION_NAMES.updateDrivers
        : firebase.FUNCTION_NAMES.createDrivers
    );

    setLoading(true);

    const { drivers } = (await fun({ drivers: _drivers })).data as {
      drivers: IDriver[];
    };

    if (!isGenericDriverRegistration) {
      if (!editMode) {
        //save driver to booking
        const addDriverToBooking = firebase.getCallableFunction(
          firebase.FUNCTION_NAMES.addDriverToBooking
        );
        const res = await addDriverToBooking({ drivers: drivers, orderId });
        console.log("res", res);
      }

      await refreshDrivers(email);
    }

    setLoading(false);
    openSuccessScreen();

    console.log("drivers", drivers);
  };

  return (
    <>
      <div className="text-xl text-center font-semibold ">
        {editMode ? "Edit your account" : "Create your account"}
      </div>
      <div className="border rounded-[10px] border-[#e5e5e5] mt-[30px]">
        <div className="bg-[#f0faec] py-[15px] px-[10px] mb-[16px] flex items-center justify-between">
          <span className="text-base leading-5">Guardian Details:</span>
        </div>
        <div className="px-[20px] pb-[20px]">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">First Name</label>
              <input
                name="guardian_first_name"
                onChange={handleOnChangeInput}
                onBlur={handleBlurInput}
                value={driver.guardian_first_name}
                type="text"
                placeholder="First Name"
                className={`border rounded-lg py-3 px-4 outline-0 text-base text-slate-500 ${errors.guardian_first_name && "border-red-500"
                  }`}
              />
              {
                <p className="text-red-500 text-sm">
                  {errors.guardian_first_name}
                </p>
              }
            </div>
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">Last Name</label>
              <input
                name="guardian_last_name"
                onChange={handleOnChangeInput}
                onBlur={handleBlurInput}
                value={driver.guardian_last_name}
                type="text"
                placeholder="Last Name"
                className={`border rounded-lg py-3 px-4 outline-0 text-base text-slate-500  ${errors.guardian_last_name && "border-red-500"
                  }`}
              />
              {
                <p className="text-red-500 text-sm">
                  {errors.guardian_last_name}
                </p>
              }
            </div>
          </div>
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">
                Date of Birth
              </label>
              <input
                name="guardian_dob"
                onChange={handleOnChangeInput}
                onBlur={handleBlurInput}
                value={driver.guardian_dob}
                type="date"
                placeholder="DD/MM/YYYY"
                className={`appearance-none border border-slate-200 rounded-lg py-3 px-4 outline-0 text-base text-slate-500  ${errors.guardian_dob && "border-red-500"
                  }`}
              />
              {<p className="text-red-500 text-sm">{errors.guardian_dob}</p>}
            </div>
            <div className="flex flex-col w-full sm:w-[calc(50%_-_10px)] mt-4">
              <label className="text-base	leading-5 mb-[10px]">
                Mobile Phone
              </label>
              <input
                name="phone_number"
                onChange={handleOnChangeInput}
                onBlur={handleBlurInput}
                value={driver.phone_number}
                type="text"
                placeholder="0412 345 678"
                className={`border rounded-lg py-3 px-4 outline-0 text-base text-slate-500  ${errors.phone_number && "border-red-500"
                  }`}
              />
              {<p className="text-red-500 text-sm">{errors.phone_number}</p>}
            </div>
          </div>
          <div className="flex flex-col mt-4 relative">
            <label className="text-base	leading-5 mb-[10px]">Address</label>
            <input
              autoComplete="off"
              type="text"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              // onBlur={onBlurValidateHandler("address")}
              value={value}
              disabled={!ready}
              name="address"
              placeholder="Address"
              className={`outline-0 px-4 py-3 text-base border rounded-xl w-full ${errors.address && "border-red-500"
                }`}
              ref={addressRef}
            />
            {<p className="text-red-500 text-sm">{errors.address}</p>}
            {status === "OK" && (
              <ul className="absolute bg-white border rounded-xl py-0.5 p-3	max-h-[350px] overflow-auto	z-[999] w-full shadow-lg top-[85px]">
                {renderSuggestions()}
              </ul>
            )}
          </div>
        </div>
      </div>
      {juniors.map((juniorDriver, index) => (
        <JuniorFormItem
          editMode={editMode}
          handleOnChangeInput={handleOnChangeInput}
          handleOnChangeSelect={handleOnChangeSelect}
          driver={juniorDriver}
          index={index}
          removeJunior={removeJunior}
          key={index}
          performValidation={performValidation}
          removeErrorFromInputField={removeErrorFromInputField}
          errors={errors}
        />
      ))}
      {!editMode && (
        <div className="mt-[30px] relative flex items-center">
          <UserAddIcon className="absolute left-[16px] z-[2] w-[18px] h-[18px]" />
          <input
            type="text"
            placeholder="Add another child"
            className="outline-0 text-sm text-[#636266] border rounded-[5px] border-[#E9E9E9] bg-[#F7F5FE] pr-[86px] pl-[40px] py-[14px] w-full z-[1] relative"
            disabled
          />
          <button
            className=" absolute right-[3px] z-[3] text-center self-center items-center py-2 px-[24px] border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
            onClick={addNewJunior}
          >
            Add
          </button>
        </div>
      )}

      <div className="mt-[30px] text-xl	mb-3 font-semibold">
        Terms and Conditions
      </div>
      <div className="flex mb-2.5">
        <div className="w-2.5 h-2.5 mr-2.5 mt-1.5">
          <div className="bg-indigo-600 w-2.5 h-2.5 rounded-full"></div>
        </div>
        <p className="font-normal	text-sm	uppercase">
          To Participate in the activity of karting you must read and sign the
          following:
        </p>
      </div>
      <div className="w-full shadow-inner px-4 py-3 mb-4 text-sm max-h-56 overflow-y-scroll">
        <p>
          <span className="underline">Risk Warning and Acknowledgement</span>
          <br />
          I am the participant named above and in consideration of Hyper Karting
          Pty Ltd (“the Provider”) providing services and/or equipment to enable
          me to participate in Karting (“the Activity”), I agree as follows:
          <br />
          1. I will comply with all rules and directions provided by the
          Provider and their employees including, but not limited to:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) Reading and complying with all safety
          instructions and signs in the kiosk and at the entrance of the
          Activity prior to my participation; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) complying with all verbal and/or video
          instructions and directions by employees of the Provider.
          <br />
          2. I understand, acknowledge and agree that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) I will exercise due care and skill while
          participating in the Activity;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) the Activity is dangerous and may result
          in serious injury, and my participation in the Activity and my use of
          any equipment related to the Activity involves inherent risks that
          will sometimes occur; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (c) the Activity requires physical fitness,
          skill and mental alertness; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (d) my participation in the Activity and my
          use of such equipment may result in my injury or illness including but
          not limited to bodily injury, disease, strains, fractures, partial
          and/or total paralysis, eye injury, blindness, heat stroke, heart
          attack, death or other ailments that could cause serious disability;{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (e) these risks and dangers may be caused by
          the negligence of the owners, employees, officers or agents of the
          Provider, the negligence of the participants, the negligence of
          others, accidents, breaches of contract the forces of nature or other
          causes. These risks and dangers may arise from foreseeable or
          unforeseeable causes;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (f) the Provider has not made any
          representations in respect to the adequacy of my skills, level of
          physical or mental fitness, psychological state or any other capacity
          in relation to my participation in the Activity; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (g) I have made or will make full disclosure
          in writing to the Provider about health issues or other relevant
          matters of any kind concerning me and which might reasonably be
          expected to impact upon my ability to participate in the Activity; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (h) I participate in the Activity solely at
          my own risk.
          <br />
          3. I further understand, acknowledge and agree that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (a) the Go Karts travel at high speeds and
          are dangerous;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (b) there is no insurance coverage for kart
          collisions or injury I may cause to another participant; and
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp; (c) participation is prohibited for drivers
          who have consumed any alcohol or illicit substances.
          <br />
          4. I release and hold harmless the Provider, and all associated
          entities of the Provider, including but not limited to Hyper Karting
          Pty Ltd, and their respective shareholders, directors, officers,
          employees and agents from all liabilities arising from my
          participation in the Activity and from my use of any equipment
          provided by the Provider including, but not limited to, any and all
          claims, actions, demands or losses for bodily injury, death, and loss
          or damage to property which I may have now or at any time in the
          future (including where such claims, actions, demands or losses arise
          from any negligent act or omission to act by the Providers).
          <br />
          5. By signing this document, I also acknowledge, agree, and understand
          that the risk warning above constitutes a 'risk warning' for the
          purposes of the relevant legislation, including for the purpose of
          section 5M of the Civil Liability Act 2002 (NSW)
          <br />
          6. I agree to indemnify, defend and hold harmless the Provider, and
          all associated entities of the Provider, including but not limited to
          Hyper Karting Pty Ltd, and their shareholders, directors, officers,
          employees and agents from any and all third party claims, liability,
          damages of every kind of nature whether known or unknown and/or costs
          (including, but not limited to, legal costs) arising from or in any
          way related to my participation in the Activity and my use of any
          equipment provided by the Providers.
          <br />
          7. I request and agree that first aid or other appropriate medical
          treatment be administered to me in such manner as the Provider or any
          of its officers or employees or agents shall deem necessary. In such
          an event, I agree to indemnify, hold harmless and forever discharge
          the parties listed above for all injury or damage incurred in the
          course of administering the First Aid.
          <br />
          8. I hereby consent to the Provider to take photographs and or video
          footage where I may feature and distribute it on any medium including
          but not limited to print and online. I understand I will not be paid
          for giving this consent and I hereby waive any right to remuneration
          or any fee in respect to its use. <br />
        </p>
        <h4>WARNING UNDER THE AUSTRALIAN CONSUMER LAW</h4>
        <p>
          Under the Australian Consumer Law (New South Wales), several statutory
          guarantees apply to the supply of certain goods and services. These
          guarantees mean that the supplier named on this form is required to
          ensure that the recreational services it supplies to you— <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• are rendered with due care and skill; and{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• are reasonably fit for any purpose which
          you, either expressly or by implication, make known to the supplier;
          and <br />
          &nbsp;&nbsp;&nbsp;&nbsp;• might reasonably be expected to achieve any
          result you have made known to the supplier. <br />
          1. Section 139A of Competition and Consumer Act 2010 (Cth) permits the
          Provider of the Recreational Activities and associated services to ask
          you to agree that the statutory guarantees under the Australian
          Consumer Law (Cth) do not apply to you (or a person for whom or on
          whose behalf you are acquiring the services <br />
          2. By signing this document, you acknowledge, agree and understand
          that, to the full extent permitted by law, the liability of the
          Provider in relation to recreational services (as that term is defined
          in the Australian Consumer Law (Cth) and any similar state laws) and
          recreational activities (as that term is defined in the Civil
          Liability Act 2002 (NSW)) for any: <br />
          &nbsp;&nbsp;&nbsp;&nbsp; a. death; <br />
          &nbsp;&nbsp;&nbsp;&nbsp; b. physical or mental injury (including the
          aggravation, acceleration or recurrence of such an injury); <br />
          &nbsp;&nbsp;&nbsp;&nbsp; c. the contraction, aggravation or
          acceleration of a disease; d. the coming into existence, the
          aggravation, acceleration or recurrence of any other condition,
          circumstance, occurrence, activity, form of behaviour, course of
          conduct or state of affairs: <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i. that is or may be
          harmful or disadvantageous to you or the community; <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii. that may result
          in harm or disadvantage to you or the community; <br />
          that may be suffered by you (or a person for whom or on whose behalf
          you are acquiring the services) resulting from the supply of
          recreational services or recreational activities is excluded. <br />
          3. By signing this document, to the full extent permitted by law, you
          (or the person for whom or on whose behalf you are acquiring the
          services) agree to waive and/or release the Provider, its servants and
          agents, from any claim, right or cause of action which you or your
          heirs, successors, executors, administrators, agents and assigns might
          otherwise have against the Provider, its servant and agents, for or
          arising out of your death or physical or mental injury, disease, loss
          and damage, or economic loss of any description whatsoever which you
          may suffer or sustain in the course of or consequential upon or
          incidental to your participation in the Activity, whether caused by
          the negligence of the Provider, its servant and agents, or otherwise.
          <br />
          4. You do not have to agree to exclude, restrict or modify or waive
          your rights against, or release, the Service Provider, its servants
          and agents, from any claims by signing this document, however the
          Service Provider may refuse to allow you to participate in the
          Recreational Activities, or to provide you with the associated
          services, if you do not agree to exclude, restrict, modify or waive
          your rights against, or release, the Service Provider, its servants
          and agents, by signing this document.
        </p>

        <p></p>
        <h4>
          For participants under 18 years of age:
          <br />
          (required for all underage participants)
        </h4>
        <p>
          I hereby: <br />
          1. confirm that I am over the age of eighteen years and that I am the
          guardian of the participant; <br />
          2. acknowledge that I have read and clearly understand each of the
          conditions hereof;
          <br />
          3. warrant and declare that the information set out in this document
          is true and correct in every particular;
          <br />
          4. acknowledge that I accept full liability and responsibility for the
          actions of the participant during their use of the activity facilities
          and will ensure that they comply with the above terms and conditions;
          and
          <br />
          5. indemnify and will keep indemnified the Provider and all associated
          entities of the Provider, including but not limited to Hyper Karting
          Pty Ltd and its officers, agents and personnel from all actions,
          claims, demands or proceedings made by or on behalf of the participant
          or any third party acting on behalf of the participant.
        </p>
      </div>
      <div className="text-xl	mb-3 font-semibold text-left">
        Please Sign Here
      </div>
      <div className="h-[200px]">
        <SignaturePad
          penColor="black"
          clearOnResize={false}
          canvasProps={{
            className:
              "sigCanvas outline-0 text-base border rounded-xl resize-none mb-5 w-full h-full",
          }}
          ref={(ref) => setSignatureRef(ref)}
          onEnd={() => {
            handleDriverFieldChange({
              signature_url: signatureRef?.toDataURL(),
            });
          }}
        />
      </div>
      <div className="mb-4 flex" onClick={clearSignature}>
        {<p className="text-red-500 text-sm">{errors.signature_url}</p>}
        <div className="text-sm underline text-slate-500 pr-1 cursor-pointer w-max ml-auto">
          Clear
        </div>
      </div>
      <div className="mt-[20px] flex items-center">
        <input
          type="checkbox"
          name="tcs_agreed"
          onChange={handleOnChangeInput}
          onBlur={handleBlurInput}
          checked={driver.tcs_agreed}
          className="w-4 h-4 mr-2.5 cursor-pointer"
          id="heyper"
        />
        <label
          htmlFor="heyper"
          className="text-[#666666] text-base leading-5 ̣font-normal"
        >
          I agree with both Hyper Karting and RaceFacers' Terms & Conditions,
          Privacy Policy and Cookies Policy.
        </label>
      </div>
      <div className="mt-[16px] flex items-center">
        <input
          type="checkbox"
          name="risk_agreement_agreed"
          onChange={handleOnChangeInput}
          onBlur={handleBlurInput}
          checked={driver.risk_agreement_agreed}
          className="w-4 h-4 mr-2.5 cursor-pointer"
          id="cookies"
        />
        <label
          htmlFor="cookies"
          className="text-[#666666] text-base leading-5 ̣font-normal"
        >
          I understand that Karting is an inherently dangerous activity which
          may cause serious injury or death and I am participating at my own
          risk.
        </label>
      </div>
      <div className="mt-[16px] flex items-center">
        <input
          type="checkbox"
          name="email_consent"
          onChange={handleOnChangeInput}
          onBlur={handleBlurInput}
          checked={driver.email_consent}
          className="w-4 h-4 mr-2.5 cursor-pointer"
          id="cookies"
        />
        <label
          htmlFor="cookies"
          className="text-[#666666] text-base leading-5 ̣font-normal"
        >
          I agree to receive email & sms notifications from Hyper Karting
          regarding: Discount Codes, Promotional Offers & Newsletter.
        </label>
      </div>

      <p className="text-red-500 text-sm mt-2">
        {errors.tcs_agreed || errors.risk_agreement_agreed}
      </p>

      <div className="text-center mt-[40px]">
        <button
          className={`text-center self-center items-center p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[335px] min-w-[100%]
          ${isSubmitDisable && "bg-gray-600 hover:bg-gray-700"}
          `}
          onClick={() => {
            handleSubmit();
          }}
        >
          {editMode ? "Update Profile" : "Create and Add driver"}
        </button>
      </div>
    </>
  );
}

function DriverForm(props: any) {
  const {
    driver,
    editMode,
    handleDriverFieldChange,
    openScreen,
    openSuccessScreen,
  }: {
    driver: IDriver;
    editMode: boolean;
    handleDriverFieldChange: Function;
    openScreen: Function;
    openSuccessScreen: Function;
  } = props;

  const bookingContext = useContext(BookingContext);
  const { isJunior } = bookingContext as IBookingContext;

  return (
    <>
      <Header
        title={editMode ? "Update driver details" : "Create your account"}
        description="Create your Hyper Karting profile to register for this race"
      />
      <StepProgress
        firstHandler={() => openScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN)}
        secondHandler={() => openScreen(SCREEN_NAMES.DRIVER_LIST)}
        thirdHandler={() => openScreen(SCREEN_NAMES.DRIVER_FORM)}
      />

      {isJunior ? (
        <JuniorDriverForm
          driver={driver}
          editMode={editMode}
          handleDriverFieldChange={handleDriverFieldChange}
          openSuccessScreen={openSuccessScreen}
        />
      ) : (
        <AdultDriverForm
          driver={driver}
          editMode={editMode}
          handleDriverFieldChange={handleDriverFieldChange}
          openSuccessScreen={openSuccessScreen}
        />
      )}
    </>
  );
}

function SuccessScreen(props: any) {
  const {
    openScreen,
    driver,
    openBookingScreen,
    openGenericRegistrationScreen,
  } = props as {
    openScreen: Function;
    editMode: boolean;
    driver: IDriver;
    openBookingScreen: Function;
    openGenericRegistrationScreen: Function;
  };
  const bookingContext = useContext(BookingContext);
  const { isGenericDriverRegistration, refreshBooking } =
    bookingContext as IBookingContext;
  const handleSubmit = async () => {
    if (isGenericDriverRegistration) {
      openGenericRegistrationScreen();
    } else {
      console.log("submitted! now refreshing");
      await refreshBooking();
      console.log("refresh completed !");

      openBookingScreen();
    }
  };
  return (
    <>
      <Header
        title={
          isGenericDriverRegistration ? "Registration Completed" : "Add driver to race"
        }
        description={
          isGenericDriverRegistration
            ? `${driver.first_name} ${driver.last_name}`
            : "Your registration is now complete!"
        }
      />
      <StepProgress
        firstHandler={() => openScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN)}
        secondHandler={() => openScreen(SCREEN_NAMES.DRIVER_LIST)}
        thirdHandler={() => openScreen(SCREEN_NAMES.SUCCESS_SCREEN)}
      />
      <div className="text-base font-normal sm:text-center">
        {isGenericDriverRegistration
          ? "Show this page to staff at the front desk!"
          : "Head back to your booking"}
      </div>
      <button
        className="text-center self-center items-center mt-5 p-2 border border-transparent text-medium font-semibold rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 sm:min-w-[335px] min-w-[100%]"
        onClick={() => {
          handleSubmit();
        }}
      >
        {isGenericDriverRegistration
          ? "Complete another registration"
          : "Continue"}
      </button>
    </>
  );
}

interface IScreen {
  [key: string]: {
    Component: React.FC;
    key: string;
    props?: object;
  };
}
const SCREEN_NAMES = {
  EMAIL_SELECTION_SCREEN: "EMAIL_SELECTION_SCREEN",
  DRIVER_LIST: "DRIVER_LIST",
  DRIVER_PREVIEW_SCREEN: "DRIVER_PREVIEW_SCREEN",
  DRIVER_FORM: "DRIVER_FORM",
  SUCCESS_SCREEN: "SUCCESS_SCREEN",
};

const defaultDriverData = {
  email: "",
  email_consent: false,
  first_name: "",
  last_name: "",
  dob: DateTime.now().toFormat("yyyy-MM-dd"),
  phone_number: "",
  gender: "male",
  address: "",
  tcs_agreed: false,
  risk_agreement_agreed: false,
  country: "AU",
  city: "",
  zip_code: "",
  guardian_dob: DateTime.now().toFormat("yyyy-MM-dd"),
  guardian_first_name: "",
  guardian_last_name: "",
};

type KeyValuePairOfType<T> = {
  [Property in keyof T]: T[Property];
};

export default function RegistrationForm() {
  const navigate = useNavigate();
  const [driver, setDriver] = useState(defaultDriverData as IDriver);
  const [editMode, setEditMode] = useState(false);

  const bookingContext = useContext(BookingContext);
  const { orderId } = bookingContext as IBookingContext;

  const handleDriverFieldChange: (obj: KeyValuePairOfType<IDriver>) => void = (
    obj
  ) => {
    setDriver((driver) => ({ ...driver, ...obj }));
  };

  const openDriverPreview = (driver: IDriver) => {
    setDriver(driver);
    setCurrentScreen(SCREEN_NAMES.DRIVER_PREVIEW_SCREEN);
  };

  const openEditDriverScreen = (driver: IDriver) => {
    setDriver(driver);
    setEditMode(true);
    setCurrentScreen(SCREEN_NAMES.DRIVER_FORM);
  };

  const openCreateDriverScreen = (driver: IDriver | undefined | null) => {
    setDriver(driver || (defaultDriverData as IDriver));
    setEditMode(false);
    setCurrentScreen(SCREEN_NAMES.DRIVER_FORM);
  };

  const openSuccessScreen = () => {
    setCurrentScreen(SCREEN_NAMES.SUCCESS_SCREEN);
  };

  const openBookingScreen = () => {
    console.log("Redirecting to", `/bookings/${orderId}`);
    navigate(`/bookings/${orderId}`);
  };

  const openGenericRegistrationScreen = () => {
    window.location.href = `/register-driver`;
  };

  const openScreen = (screenName: keyof typeof SCREEN_NAMES) => {
    setCurrentScreen(screenName);
  };

  const SCREENS: IScreen = {
    [SCREEN_NAMES.EMAIL_SELECTION_SCREEN]: {
      key: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
      Component: EmailScreen,
      props: {
        goToNextPage: () => {
          setCurrentScreen(SCREEN_NAMES.DRIVER_LIST);
        },
        openScreen,
      },
    },
    [SCREEN_NAMES.DRIVER_LIST]: {
      key: SCREEN_NAMES.DRIVER_LIST,
      Component: DriverListScreen,
      props: {
        goToPreviousPage: () => {
          setCurrentScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN);
        },
        openDriverPreview,
        openCreateDriverScreen,
        openScreen,
      },
    },
    [SCREEN_NAMES.DRIVER_PREVIEW_SCREEN]: {
      key: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
      Component: DriverPreviewScreen,
      props: {
        driver,
        openEditDriverScreen,
        openScreen,
        openSuccessScreen,
      },
    },
    [SCREEN_NAMES.DRIVER_FORM]: {
      key: SCREEN_NAMES.DRIVER_FORM,
      Component: DriverForm,
      props: {
        driver,
        editMode,
        goToPreviousPage: () => {
          setCurrentScreen(SCREEN_NAMES.DRIVER_PREVIEW_SCREEN);
        },
        goToNextPage: () => {
          setCurrentScreen(SCREEN_NAMES.EMAIL_SELECTION_SCREEN);
        },
        handleDriverFieldChange,
        openScreen,
        openSuccessScreen,
      },
    },
    [SCREEN_NAMES.SUCCESS_SCREEN]: {
      key: SCREEN_NAMES.SUCCESS_SCREEN,
      Component: SuccessScreen,
      props: {
        driver,
        openScreen,
        editMode,
        openBookingScreen,
        openGenericRegistrationScreen,
      },
    },
  };

  const [currentScreen, setCurrentScreen] = useState<string>(
    SCREEN_NAMES.EMAIL_SELECTION_SCREEN
  );

  const { Component, props } = SCREENS[currentScreen];

  return (
    <div className="bg-black justify-center sm:flex sm:items-center p-5 min-h-[calc(100vh_-_75px)] sm:bg-[url('./images/hyperkating_bg.jpg')] bg-no-repeat bg-cover sm:p-0 sm:min-h-full">
      <div className="p-5 sm:p-0 sm:py-10 sm:min-h-full flex items-center justify-center">
        <div className="p-5 flex flex-col bg-white rounded-xl shadow-sm sm:min-w-[550px] sm:max-w-[550px] sm:mx-auto sm:my-10	 sm:p-12">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
}

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Modal from "../../components/Shared/Modal";
import { getFinancialStatus } from "./utilities";
import { Fragment } from "react";
import {
  IDiscountApplication,
  IGiftCard,
  ILineItemDiscountAllocation,
  IOrder,
  IOrderLineItem,
  IRefund,
  IRefundLineItem,
} from "shopify-api-node";
import ToggleSwitch from "../../components/Shared/ToggleSwitch";
import { useSession } from "../../components/Session";
import axios from "axios";
import { CheckIcon } from "@heroicons/react/solid";
import { useBookingSession } from "../../context/BookingSession";
import QrCode from "qrcode.react";
import { useFirebase } from "../../components/Firebase";
import { doc, getDoc } from "firebase/firestore";
import CardNumber from "../arcade-cards/CardNumberInput";
import { useArcadeCard } from "../../context/ArcadeCard";
import { IFirebaseOrder } from "../../interfaces/firebaseEntities";
import ModalSection from "../../components/Modal/ModalSection";
import { DateTime } from "luxon";
import Button from "../../components/Shared/Button";
import { ILineItemWithOrder } from "../../interfaces/Session";
import { getRestockTypeColorClasses, getRestockTypeLabel } from "../../components/Utilities/dashboard";

type TModelType = "ORDER_DETAIL" | "REFUND" | "REFUND_STATUS" | "ARCADE_CARDS";

interface RefundData {
  refund: IRefund;
  giftCard: IGiftCard;
}

interface ModalProps {
  show: boolean;
  handleClose: () => void;
}
interface BookingDetailsModalProps extends ModalProps {
  modal: TModelType;
  order: IOrder;
  handleViewRegistrationModalOpen?: (booking: ILineItemWithOrder) => void;
}

interface RefundModalProps extends ModalProps {
  order: IOrder;
  discountCode?: IDiscountApplication;
  onRefundSuccess: (data: RefundData) => void;
  checkRefundIsInProgress: () => Promise<boolean>;
}

interface OrderDetailsProps extends ModalProps {
  order: IOrder;
  discountCode?: IDiscountApplication;
  onRefundBtnClick: () => void;
  handleViewRegistrationModalOpen?: (booking: ILineItemWithOrder) => void;
}

interface ArcadeCardsProps extends ModalProps {
  order: IOrder;
  onRefundBtnClick: () => void;
}

interface RefundStatusProps extends ModalProps {
  order: IOrder;
  refundData: RefundData | undefined;
  onFinish: () => void;
}

const BookingDetailsModal = (props: BookingDetailsModalProps) => {
  const { order, modal } = props;
  const [modelType, setModelType] = useState<TModelType>(modal);
  const [refundData, setRefundData] = useState<RefundData | undefined>();
  const [refunInProgress, setRefunInProgress] = useState(false);
  const { refreshSessions } = useBookingSession();

  const firebase = useFirebase();

  const db = useMemo(() => firebase.db, [firebase]);
  const discountCode = useMemo(() => order.discount_applications.find((dCode) => dCode.code), [order]);

  const getRefundLogOfBooking: (order: IOrder) => any = useCallback(
    async (order) => {
      try {
        const data = await getDoc(doc(db, "logs", `HKR#${order.id}`));
        if (data.exists()) {
          return data.data();
        }
        return undefined;
      } catch (error) {
        console.log("error", error);
        return undefined;
      }
    },
    [db]
  );

  const checkRefundIsInProgress = useCallback(async () => {
    const log = await getRefundLogOfBooking(order);
    if (log?.status === "IN_PROGRESS") {
      setRefunInProgress(true);
      alert("Other refund is in progress");
      return true;
    }

    if (refunInProgress) {
      setRefunInProgress(false);
      refreshSessions();
    }
    return false;
  }, [getRefundLogOfBooking, order, refunInProgress, refreshSessions]);

  const onRefundBtnClick = useCallback(async () => {
    const inProgress = await checkRefundIsInProgress();
    if (inProgress) {
      return;
    }
    setModelType("REFUND");
  }, [checkRefundIsInProgress]);

  switch (modelType) {
    case "ORDER_DETAIL":
      return <OrderDetails {...props} discountCode={discountCode} onRefundBtnClick={onRefundBtnClick} />;

    case "REFUND":
      return (
        <Refund
          order={order}
          show={modelType === "REFUND"}
          handleClose={() => setModelType(modal)}
          onRefundSuccess={(data: RefundData) => {
            setRefundData(data);
            setModelType("REFUND_STATUS");
          }}
          checkRefundIsInProgress={checkRefundIsInProgress}
          discountCode={discountCode}
        />
      );

    case "REFUND_STATUS":
      return (
        <RefundStatus
          order={order}
          show={modelType === "REFUND_STATUS"}
          handleClose={props.handleClose}
          refundData={refundData}
          onFinish={() => setModelType("ORDER_DETAIL")}
        />
      );

    case "ARCADE_CARDS":
      return <ArcadeCards {...props} onRefundBtnClick={onRefundBtnClick} />;

    default:
      return <h1>{modelType}</h1>;
  }
};

function CommonOrderSections({ order }: { order: IOrder }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = useCallback(() => {
    const orderNumber = order.name;
    if (orderNumber) {
      navigator.clipboard.writeText(orderNumber.toString());
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [order]);

  const qrContent = useMemo(() => {
    const { source_name, source_identifier, id } = order;
    const url = `https://hyper-karting.myshopify.com/orders`;
    if (source_name === "web" && id) {
      return `${url}?receipt_by_id=${id}`;
    } else if (source_name === "pos" && source_identifier) {
      return `${url}?receipt=${source_identifier}`;
    }
    return "";
  }, [order]);
  // console.log("order data ===> ", order);
  const createdDate = DateTime.fromISO(order?.created_at).toFormat(
    "dd/MM/yyyy"
  );
  const updatedDate = DateTime.fromISO(order?.updated_at).toFormat(
    "dd/MM/yyyy"
  );
  return (
    <div className="w-[30%] flex flex-col gap-2 overflow-auto">
      <ModalSection title="Customer">
        <div className="font-normal text-xs leading-4">
          Name: &nbsp;
          <span className="font-medium">
            {order.customer?.first_name}&nbsp;
            {order.customer?.last_name}
          </span>
        </div>
        <div className="font-normal text-xs leading-4">
          Email: &nbsp;
          <span className="font-medium">
            {order.customer?.email ? order.customer?.email : "-"}
          </span>
        </div>
        <div className="font-normal text-xs leading-4">
          Phone: &nbsp;
          <span className="font-medium">
            {order.customer?.default_address?.phone
              ? order.customer?.default_address?.phone
              : "-"}
          </span>
        </div>
      </ModalSection>

      <ModalSection title="Status">
        <div className="font-medium text-xs leading-4">
          {getFinancialStatus(order!!)}
        </div>
      </ModalSection>

      {order?.refunds?.length !== 0 &&
        order?.refunds[0].transactions?.length !== 0 && (
          <ModalSection title="Refunds">
            <div className="font-medium text-xs leading-4">
              ${order?.refunds[0]?.transactions[0]?.amount}{" "}
              {order?.note ? `-${order.note}` : " - No notes were provided"}
            </div>
          </ModalSection>
        )}

      <ModalSection title="Order">
        <div className="flex gap-2">
          <div>
            <div className="font-normal text-xs leading-4">
              Order No:&nbsp;
              <span className="text-purple-600 font-medium">{order.name}</span>
              <button
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 p-1 ml-2 font-normal text-xs text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                onClick={handleCopy}
                disabled={isCopied}
              >
                {isCopied ? "Copied" : "Copy"}
              </button>
            </div>
            <div className="font-normal mt-1 text-xs leading-4">
              Order Date:&nbsp;
              <span className="font-medium text-purple-600">{createdDate}</span>
            </div>
            <div className="font-normal mt-1 text-xs leading-4">
              Last Modified Date:&nbsp;
              <span className="font-medium text-purple-600">{updatedDate}</span>
            </div>
            <div className="font-normal mt-1 text-xs leading-4">
              No of products:&nbsp;
              <span className="font-medium text-gray-700">
                {order?.line_items.length}
              </span>
            </div>
            <div className="font-normal text-xs leading-4 mt-1">
              Discounts:&nbsp;
              <span className="font-medium text-purple-500">
                ${order.current_total_discounts}
              </span>
            </div>
            <div className="font-normal text-xs leading-4 mt-1">
              Total:&nbsp;
              <span className="font-medium text-indigo-500">
                ${order.current_total_price}
              </span>
            </div>
          </div>
        </div>
        {qrContent && (
          <div className="flex justify-center">
            <QrCode
              value={qrContent}
              // size={250}
              // bgColor="#000"
              // fgColor="#fff"
              level="H"
              includeMargin
              // imageSettings={{
              //   src: "https://cdn.bfldr.com/LCMQM79M/as/tn6r9nsq5bg2c6txfsrpm/HK-HKAPP?auto=webp&format=png",
              //   height: 35,
              //   width: 35,
              //   excavate: true,
              // }}
            />
          </div>
        )}
      </ModalSection>

      <ModalSection title="Order notes">
        <div className="font-medium text-xs leading-4 mt-1">
          {order?.note ? order.note : "No notes were provided"}
        </div>
      </ModalSection>
    </div>
  );
}

function OrderDetails(props: OrderDetailsProps) {
  const {
    order,
    show,
    handleClose,
    onRefundBtnClick,
    handleViewRegistrationModalOpen,
  } = props;  

  const lineItems = useMemo(() => order.line_items.filter(li=>(li as any).current_quantity), [order])
  const refundLineItems:(IRefundLineItem & {
    part_refunded: boolean
  })[] = useMemo(
    () =>{
      return (order?.refunds ?? []).map((refund) => refund.refund_line_items).flat()
      .map(rli=>{
        const li = order.line_items.find(li=>li.id === rli.line_item_id);
        return {
          ...rli,
          part_refunded: !!(li as any).current_quantity
        }
      })
    },
    [order]
  );

  const haveAnyDiscount = useMemo(() => !!order?.discount_applications?.length, [order])

  return (
    <Modal
      title={
        handleViewRegistrationModalOpen ? (
          <div className="flex items-center gap-2">
            <p>Booking Details</p>
            <Button
              variant="small"
              type="secondary"
              onClick={() => {
                handleClose();
                handleViewRegistrationModalOpen({
                  ...order.line_items[0], // This is not correct but here we need only order
                  order,
                });
              }}
            >
              Registration Details
            </Button>
          </div>
        ) : (
          "Booking Details"
        )
      }
      show={show}
      onClose={handleClose}
    >
      <div className="flex mt-2 justify-between p-5 gap-6 max-h-[590px] overflow-hidden">
        <CommonOrderSections order={order} />
        <div className="w-[70%] overflow-auto">
          <ModalSection title="Booking Summary">
            <div className=""></div>
            <table className="table-auto w-full mt-5 rounded border-collapse border">
              <thead className="border-b font-medium border-gray-200 p-4">
                <tr>
                  <th
                    scope="col"
                    className="text-sm leading-4 text-purple-600 pl-[10px] py-[10px]"
                  >
                    Item Description
                  </th>
                  <th
                    className="text-center text-sm text-purple-600 py-[10px]"
                    scope="col"
                  >
                    Item Price
                  </th>
                  <th
                    className="text-center text-sm text-purple-600 py-[10px]"
                    scope="col"
                  >
                    Quantity
                  </th>
                  <th
                    className="text-center text-sm text-purple-600 py-[10px]"
                    scope="col"
                  >
                    Subtotal
                  </th>
                  {haveAnyDiscount && (
                    <th 
                      className="text-sm text-purple-600 py-[10px]"
                      scope="col"
                    >
                        Discount Details
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {lineItems.map((item, index) => (
                  <OrderDetailItem
                    key={item.id}
                    item={item}
                    index={index + 1}
                    discountApplications={order.discount_applications}
                    haveAnyDiscount={haveAnyDiscount}
                  />
                ))}
                {refundLineItems.map((item, index) => (
                  <OrderDetailRefundItem
                    key={item.id}
                    item={item}
                    index={order.line_items.length + index + 1}
                    haveAnyDiscount={haveAnyDiscount}
                  />
                ))}
              </tbody>
            </table>
          </ModalSection>
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <div
          className="cursor-pointer inline-flex justify-center rounded-full border border-transparent bg-white px-4 py-2 text-sm font-medium text-purple-500 border-purple-500 hover:border-purple-600 hover:text-purple-600"
          onClick={onRefundBtnClick}
        >
          Refund to Gift Card
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          className="inline-flex justify-center rounded-full border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-600"
          href={`https://hyper-karting.myshopify.com/admin/orders/${order?.id}`}
        >
          View on Shopify
        </a>
      </div>
    </Modal>
  );
}

function ArcadeCards(props: ArcadeCardsProps) {
  const { order, show, handleClose, onRefundBtnClick } = props;

  const { cardNumbers, getItemsFromOrder } = useArcadeCard();

  const items = useMemo(
    () => getItemsFromOrder(order as IFirebaseOrder),
    [getItemsFromOrder, order]
  );

  return (
    <Modal title={"Booking Details"} show={show} onClose={handleClose}>
      <div className="flex mt-2 justify-between p-5 gap-6 max-h-[590px] overflow-hidden">
        <CommonOrderSections order={order} />
        <div className="w-[70%] overflow-auto">
          <ModalSection title="Arcade Cards">
            <div className="flex flex-col gap-2">
              {items.map((item) => (
                <Fragment key={item.id}>
                  <div className="p-4 flex justify-between bg-gray-100 rounded-md items-center">
                    <div className="flex w-[70%] items-center">
                      <div className="font-bold text-xs leading-4 text-gray-700 mr-2">
                        #{item.index}
                      </div>
                      <div className="mr-2">
                        <div className="font-medium text-xs leading-4 text-gray-700">
                          {item.li.title}
                        </div>
                        <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                          {item.li.variant_title}
                        </div>
                        <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                          SKU:{item.li.sku}
                        </div>
                      </div>
                      <div className="">
                        <CardNumber
                          variant="small"
                          card_number={cardNumbers[item.id]?.card_number || ""}
                          item={item}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="font-medium text-xs leading-none text-gray-700">
                        {item.li.price} &times; 1&nbsp;
                      </div>
                    </div>
                    <div>
                      <div className="font-bold text-xs leading-none text-gray-700">
                        ${Number(item.li.price).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </ModalSection>
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <div
          className="cursor-pointer inline-flex justify-center rounded-full border border-transparent bg-white px-4 py-2 text-sm font-medium text-purple-500 border-purple-500 hover:border-purple-600 hover:text-purple-600"
          onClick={onRefundBtnClick}
        >
          Refund
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          className="inline-flex justify-center rounded-full border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-600"
          href={`https://hyper-karting.myshopify.com/admin/orders/${order?.id}`}
        >
          View on Shopify
        </a>
      </div>
    </Modal>
  );
}

function Refund(props: RefundModalProps) {
  const { order, show, discountCode, handleClose, onRefundSuccess, checkRefundIsInProgress } = props;

  const [refundLineItems, setRefundLineItems] = useState<{[key: string]: number;}>(order.line_items.reduce((acc, ele) => ({ ...acc, [ele.id]: 0 }), {}));
  const [refundAmount, setRefundAmount] = useState(0);
  const [note, setNote] = useState("");
  const [restockAtLocation, setRestockAtLocation] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const racesRef = useRef<HTMLDivElement | null>(null);

  const { permissions, getToken } = useSession();
  const trackStaff = permissions?.roles?.includes("TRACK_STAFF");

  const lineItems: {
    [key: string]: IOrderLineItem;
  } = useMemo(
    () =>
      order.line_items.reduce((acc, ele) => ({ ...acc, [ele.id]: ele }), {}),
    [order]
  );

  const RefundedAmount = useMemo(
    () =>
      order.refunds
        .map((refund) =>
          refund.refund_line_items.reduce(
            (total, li) => total + +li.subtotal_set.shop_money.amount,
            0
          )
        )
        .reduce((total, refundAmount) => refundAmount + total, 0),
    [order]
  );

  const incrementQuantity = useCallback(
    (id: number) => () => {
      setRefundLineItems((old) => ({
        ...old,
        [id]: Math.min(old[id] + 1, lineItems[id].quantity),
      }));
    },
    [lineItems]
  );

  const decremantQuantity = useCallback(
    (id: number) => () => {
      setRefundLineItems((old) => ({
        ...old,
        [id]: Math.max(old[id] - 1, 0),
      }));
    },
    []
  );

  const getTotalDiscount = useCallback((li:IOrderLineItem) => (li.discount_allocations || []).reduce((total,discount_allocation)=>Number(discount_allocation.amount) + total,0),[])
  
  const getDiscountedPrice = useCallback((li:IOrderLineItem,q:number) => {
    const discount = getTotalDiscount(li);
    const amount = (((+li.price * li.quantity) - discount) / li.quantity)*q;
    return amount;
  },[getTotalDiscount])
  

  const itemSubTotal = useMemo(() => Object.keys(refundLineItems).reduce((total, id) => {
    return total + (getDiscountedPrice(lineItems[id],refundLineItems[id]));
  }, 0),[refundLineItems, lineItems,getDiscountedPrice]);
  
  const totalDiscountOnRefundItems = useMemo(() => Object.keys(refundLineItems).reduce((total, id) => {
    return total + ((getTotalDiscount(lineItems[id]) / lineItems[id].quantity) * refundLineItems[id]);
  }, 0),[refundLineItems, lineItems, getTotalDiscount]);
  
  const withoutDiscountTotalRefund = useMemo(() => Object.keys(refundLineItems).reduce((total, id) => {
    return total + (+lineItems[id].price * refundLineItems[id]);
  }, 0),[refundLineItems, lineItems]);

  useEffect(() => {
    setRefundAmount(itemSubTotal);
  }, [itemSubTotal]);

  const MAX_REFUND_POSSIBLE = useMemo(
    () => (trackStaff ? 50 : 300),
    [trackStaff]
  );

  const MAX_REFUND_ALLOWED = useMemo(
    () => Math.max(0, MAX_REFUND_POSSIBLE - RefundedAmount),
    [MAX_REFUND_POSSIBLE, RefundedAmount]
  );

  // const refundAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   if (!isNaN(e.target.value as any)) {
  //     setRefundAmount(Number(e.target.value));
  //   }
  // }, []);

  const disableRefundBtn = useMemo(
    () => refundAmount <= 0 || refundAmount > MAX_REFUND_ALLOWED || !note,
    [refundAmount, MAX_REFUND_ALLOWED, note]
  );

  const errInAmountField = useMemo(
    () => refundAmount <= 0 || refundAmount > MAX_REFUND_ALLOWED,
    [refundAmount, MAX_REFUND_ALLOWED]
  );

  const errInNoteField = useMemo(() => !note, [note]);

  const handleRefund = useCallback(async () => {
    try {
      setIsLoading(true);
      const inProgress = await checkRefundIsInProgress();
      if (inProgress) {
        return;
      }
      const items = Object.keys(refundLineItems)
        .filter((id) => refundLineItems[id] > 0)
        .map((id) => ({
          id,
          quantity: refundLineItems[id],
        }));

      const token = await getToken();

      const { data } = await axios.post(
        "https://us-central1-hyper-karting.cloudfunctions.net/refund",
        // "http://localhost:5001/hyper-karting/us-central1/refund",
        {
          orderId: order.id,
          items,
          amount: refundAmount,
          note,
          restock: restockAtLocation,
          customerId: order.customer?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("res", data);
      if (data.success) {
        onRefundSuccess({
          refund: data.refund as IRefund,
          giftCard: data.giftCard as IGiftCard,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response?.data);
      } else {
        console.log(error);
      }
      alert("Error");
    } finally {
      setIsLoading(false);
    }
  }, [
    refundAmount,
    refundLineItems,
    note,
    order,
    getToken,
    restockAtLocation,
    onRefundSuccess,
    checkRefundIsInProgress,
  ]);

  const getRefundedQuantity = useCallback(
    (id: number) => {
      return order.refunds
        .map((refund) => refund.refund_line_items)
        .flat()
        .filter((li) => li.line_item_id === id)
        .reduce((total, rli) => total + rli.quantity, 0);
    },
    [order]
  );

  //remove focus from textarea and scroll to top
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        racesRef.current?.scrollTo({
          top: 0,
          behavior: "auto",
        });
        textAreaRef.current?.blur();
      });
    }
  }, [show]);

  return (
    <Modal title="Refund" show={show} onClose={handleClose}>
      <div className="flex mt-2 justify-between p-5 gap-6 overflow-hidden max-h-[590px]">
        <div className="w-[60%] overflow-x-auto" ref={racesRef}>
          <ModalSection
            title="Races"
            footerContent={
              <div className="flex items-center gap-2">
                <ToggleSwitch
                  defaultValue={restockAtLocation}
                  onChange={setRestockAtLocation}
                />
                <span className="text-xs font-normal leading-[11px] text-gray-700">
                  Restock at this location
                </span>
              </div>
            }
            headerContent={
              <div className="w-full flex justify-end text-xs pr-4">
                <div className="w-[60px] text-center">Subtotal</div>
                <div className="w-[60px] text-center">Total</div>
              </div>
            }
          >
            <div className="flex flex-col gap-2">
              {order?.line_items.map((item, index) => (
                <Fragment key={item.id}>
                  <div className="p-4 flex justify-between bg-gray-100 rounded-md items-center">
                    <div className="flex w-[70%] items-center">
                      <div className="font-bold text-xs leading-4 text-gray-700 mr-2">
                        #{index + 1}
                      </div>
                      <div>
                        <div className="font-medium text-xs leading-4 text-gray-700">
                          {item.title}
                        </div>
                        <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                          {item.variant_title}
                        </div>
                        <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                          SKU:{item.sku}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-3 bg-white font-medium text-xs leading-none text-gray-700 items-center justify-center p-1 rounded-full">
                        <div
                          className={`text-base px-2 ${
                            refundLineItems[item.id] === 0
                              ? "text-gray-200 pointer-events-none"
                              : "cursor-pointer text-gray-700"
                          }`}
                          onClick={decremantQuantity(item.id)}
                        >
                          -
                        </div>
                        <div>
                          {refundLineItems[item.id]}/
                          {lineItems[item.id].quantity -
                            getRefundedQuantity(item.id)}
                        </div>
                        <div
                          className={`text-base px-2 ${
                            refundLineItems[item.id] >=
                            lineItems[item.id].quantity -
                              getRefundedQuantity(item.id)
                              ? "text-gray-200 pointer-events-none"
                              : "cursor-pointer text-gray-700"
                          }`}
                          onClick={incrementQuantity(item.id)}
                        >
                          +
                        </div>
                      </div>
                    </div>
                    <div className="w-[120px] flex">
                      <div className="flex-1 text-center">
                        <div className="font-bold text-xs leading-none text-gray-700">
                          ${(Number(item.price) * refundLineItems[item.id]).toFixed(2)}
                        </div>
                      </div>
                      <div className="flex-1 text-center">
                        <div className="font-bold text-xs leading-none text-gray-700">
                          ${(getDiscountedPrice(item,refundLineItems[item.id])).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </ModalSection>

          <ModalSection title="Reason for refund" className="mt-3">
            <div>
              <textarea
                ref={textAreaRef}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Type here..."
                className={`rounded-md w-full border font-montserrat text-xs font-normal leading-normal p-3 ${
                  errInNoteField
                    ? "border-red-600 bg-red-600 bg-opacity-5"
                    : "border-gray-200"
                }`}
                autoFocus={false}
                defaultValue={""}
              />

              <span className="text-xs font-medium leading-[115%] text-gray-700">
                Only you and other staff can see this reason
              </span>
            </div>
          </ModalSection>
        </div>
        <div className="w-[40%] flex flex-col gap-2 overflow-x-auto">
          <ModalSection title="Summary">
            <div className="font-normal text-xs leading-normal flex justify-between">
              Items Subtotal:
              <span className="font-medium">${Number(withoutDiscountTotalRefund).toFixed(2)}</span>
            </div>
            <div className="font-normal text-xs leading-normal flex justify-between mt-2">
              Tax:
              <span className="font-medium">{order.current_total_tax}</span>
            </div>
            <div className="font-normal text-xs leading-normal flex justify-between mt-2">
              Shipping:
              <span className="font-medium">{order.total_shipping_price_set.shop_money.amount}</span>
            </div>
            {
              !!discountCode && (
                <div className="font-normal text-xs leading-normal flex justify-between mt-2">
                  Discount Code:
                  <span className="font-medium">{discountCode.code}</span>
                </div>
             )
            }
            {
              order.current_total_discounts && (
                <div className="font-normal text-xs leading-normal flex justify-between mt-2">
                  Discount:
                  <span className="font-medium">${Number(totalDiscountOnRefundItems).toFixed(2)}</span>
                </div>
              )
            }
            <div className="text-xs leading-normal flex justify-between font-bold mt-2">
              Refund total:
              <span className="font-bold">${Number(itemSubTotal).toFixed(2)}</span>
            </div>
          </ModalSection>
          <ModalSection title="Refund amount">
            <div
              className={`p-3 gap-2 rounded-md border border-gray-200 flex ${
                errInAmountField
                  ? "border-red-600 bg-red-600 bg-opacity-5"
                  : "border-gray-200"
              }`}
            >
              <div className="text-gray-300 text-base font-semibold">
                <span>$ {refundAmount}</span>
              </div>

              {/* <input
                type="number"
                className={`flex-1 text-gray-700 ${
                  disableRefundBtn ? "bg-red-600 bg-opacity-5" : ""
                }`}
                value={refundAmount}
                onChange={refundAmountChange}
              /> */}
            </div>
            <span className="inline-block w-full text-xs  font-medium leading-[115%] text-gray-700">
              ${MAX_REFUND_ALLOWED} available for refund
            </span>
            {RefundedAmount > 0 && (
              <span className="text-xs  font-medium leading-[115%] text-purple-700">
                ${RefundedAmount} refunded
              </span>
            )}
          </ModalSection>
          <div
            className={`cursor-pointer inline-flex justify-center rounded-full border border-transparent px-4 py-2 text-sm font-medium text-white ${
              disableRefundBtn || refundAmount <= 0
                ? "bg-gray-200 pointer-events-none"
                : "bg-purple-500 hover:bg-purple-600"
            }`}
            onClick={handleRefund}
          >
            {isLoading ? "..." : `Refund $${refundAmount}`}
          </div>
        </div>
      </div>
    </Modal>
  );
}

function RefundStatus(props: RefundStatusProps) {
  const { refreshSessions } = useBookingSession();
  const { order, handleClose, show, refundData, onFinish } = props;
  return (
    <Modal
      show={show}
      onClose={() => {
        refreshSessions();
        handleClose();
      }}
      className="max-w-xl"
    >
      <div className="flex mt-2 justify-between p-5 gap-6 max-h-[590px] overflow-hidden">
        <div className="flex gap-5 justify-center items-center flex-col w-full">
          <div className="w-12 h-12 bg-purple-600 rounded-full m-auto flex justify-center items-center">
            <CheckIcon className="text-white p-2" />
          </div>

          <p className="text-[16px] font-semibold leading-normal text-center">
            Refund for Order{" "}
            <span className="text-purple-600">{order.name}</span> has been
            successfully processed.
          </p>

          <p className="text-[12px] font-normal leading-normal text-gray-700 text-center">
            A digital Gift Card has been issued to the customer's accounts
            associated with the original order.
          </p>

          <ModalSection title="Gift Card Details" className="w-full">
            <div className="font-normal text-xs leading-normal flex justify-between mt-2">
              Gift Card Code:
              <span className="font-bold">{refundData?.giftCard?.code}</span>
            </div>
            <div className="font-normal text-xs leading-normal flex justify-between mt-2">
              Gift Card Value:
              <span className="font-bold">
                ${refundData?.giftCard?.balance}
              </span>
            </div>
            <div className="font-normal text-xs leading-normal flex justify-between mt-2">
              Expiry Date:
              <span className="font-bold">
                {refundData?.giftCard?.expires_on}
              </span>
            </div>
          </ModalSection>
          <p className="text-[12px] font-normal leading-normal text-gray-700 text-center">
            Please inform the customer about the issued Gift Card and provide
            any necessary assistance.
          </p>
          <div
            className={`cursor-pointer inline-flex justify-center rounded-full border border-transparent px-4 py-2 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700`}
            onClick={() => {
              refreshSessions();
              onFinish();
            }}
          >
            Finish
          </div>
        </div>
      </div>
    </Modal>
  );
}

function OrderDetailItem({
  item,
  index,
  discountApplications,
  haveAnyDiscount
}: {
  item: IOrderLineItem;
  index: number;
  discountApplications: IDiscountApplication[];
  haveAnyDiscount: boolean
}) {
  return (
    <Fragment key={item.id}>
      <tr className="bg-gray-100 border">
        <td className="pl-[10px] py-[10px]">
          <div className="flex w-[70%] items-center">
            <div className="font-bold text-xs leading-4 text-gray-700 mr-2">
              #{index}
            </div>
            <div>
              <div className="font-medium text-xs leading-4 text-gray-700">
                {item.title}
              </div>
              <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                {item.variant_title}
              </div>
              <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                SKU:{item.sku}
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">
          <div className="font-medium text-xs leading-none text-gray-700">
            {item.price}
          </div>
        </td>
        <td className="text-center">
          <div className="font-medium text-xs leading-none text-gray-700">
            {(item as any).current_quantity}
          </div>
        </td>
        <td className="text-center">
          <div className="font-bold text-xs leading-none text-gray-700">
            ${(Number(item.price) * item.quantity).toFixed(2)}
          </div>
        </td>
        {
          haveAnyDiscount && (
            <td>
              {
                !!item?.discount_allocations?.length && (
                    <div>
                      {item.discount_allocations.map((discount_allocation,index)=><DiscountLine discountApplications={discountApplications} discount_allocation={discount_allocation} key={index}/>)}
                      <div className="font-medium text-xs leading-4 text-gray-700">
                        Total: ${((+item.price * item.quantity) - +item.discount_allocations.reduce((total,e)=>total + +e.amount,0)).toFixed(2)}
                      </div>
                    </div>
                )
              }
            </td>
          )
        }
      </tr>
    </Fragment>
  );
}
function OrderDetailRefundItem({
  item,
  index,
  haveAnyDiscount
}: {
  item: IRefundLineItem & {
    part_refunded: boolean
  };
  index: number;
  haveAnyDiscount: boolean;
}) {
  return (
    <Fragment key={item.id}>
      <tr className="bg-gray-100 border">
        <td className="pl-[10px] py-[10px]">
          <div className="flex w-[70%] items-center">
            <div className="font-bold text-xs leading-4 text-gray-700 mr-2">
              #{index}
            </div>
            <div>
              <div className="font-medium text-xs leading-4 text-gray-700">
                {item.line_item.title}
                <span className={`font-medium text-[0.75rem] leading-tight text-white capitalize w-max px-1 ml-1 rounded inline-block ${getRestockTypeColorClasses(item.restock_type)}`}>{getRestockTypeLabel(item.restock_type,item.part_refunded)}</span>
              </div>
              <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                {item.line_item.variant_title}
              </div>
              <div className="font-medium text-[0.5rem] leading-tight text-gray-400">
                SKU:{item.line_item.sku}
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">
          <div className="font-medium text-xs leading-none text-gray-700">
            {item.line_item.price}
          </div>
        </td>
        <td className="text-center">
          <div className="font-medium text-xs leading-none text-gray-700">
            {item.quantity}
          </div>
        </td>
        <td className="text-center">
          <div className="font-bold text-xs leading-none text-gray-700">
            ${Number(item.subtotal).toFixed(2)}
          </div>
        </td>
        {
          haveAnyDiscount && <td></td>
        }
      </tr>
    </Fragment>
  );
}

const DiscountLine = ({discountApplications,discount_allocation}:{discount_allocation:ILineItemDiscountAllocation; discountApplications: IDiscountApplication[] })=>{
  const discountApplication = useMemo(() => discountApplications[discount_allocation.discount_application_index], [discountApplications,discount_allocation])
  const title = useMemo(() => discountApplication.code ? "[Discount Code] "+discountApplication.code : discountApplication.title, [discountApplication])
  const amount = useMemo(() => Number(discount_allocation.amount).toFixed(2), [discount_allocation])
  return (
    <div className="font-medium text-xs leading-4 text-gray-700">
      {title}: {amount}
    </div>
  )
}

export default BookingDetailsModal;

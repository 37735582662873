import React, { useMemo } from 'react'
import { TLogType, TObjFromArray } from '../../interfaces'
import { IPriceChangerLog,  TLogDoc } from '../../interfaces/types';
import THead from './THead';
import TRow from './TRow';
import { IProduct } from 'shopify-api-node';

interface Props{
    searchText: string;
    logType: TLogType;
    logs: TObjFromArray<TLogDoc>;
    products: IProduct[]
}

const Table = ({ logType, logs, products, searchText }:Props) => {
  const filteredLogs = useMemo(() => {
    let filteredLogs = Object.values(logs);
    if(logType === "PRICE_CHANGER"){
      filteredLogs =  getLatestLogByKey<IPriceChangerLog>(Object.values(logs) as IPriceChangerLog[], "priductId");
    }
    // if(logType === "QUANTITY_ADJUSTED"){
    //   return getLatestLogByKey<IQuantityAdjustLog>(Object.values(logs) as IQuantityAdjustLog[], "productId");
    // }
    return filteredLogs.filter(log => JSON.stringify(log || {}).toLowerCase().includes(searchText.toLowerCase()));
  }, [logs, logType, searchText])
  return (
    <div
        className={`dashboard-session-table-wrapper overflow-auto h-[calc(100%_-_3.5rem)] m-7`}
      >
        <table className="min-w-full h-[1px] ">
          <THead logType={logType}/>
          <tbody className="bg-white divide-y divide-gray-300">
            {filteredLogs.map((log) => (
              <TRow logType={logType} log={log} key={log.ts} allLogs={logs} products={products}/>
            ))}
          </tbody>
        </table>
        {!Object.keys(logs).length && <div className="text-center p-2 text-gray-400">No Records found</div>}
      </div>
  )
}

function getLatestLogByKey<T extends { ts: number }>(logs: T[],key: keyof T): T[] {
  const map = new Map<string, T>();

  logs.forEach(log => {
    const mapKey = log[key]?.toString(); // Convert the key to a string

    if (mapKey) {
      const existingLog = map.get(mapKey);

      if (!existingLog || log.ts > existingLog.ts) {
        map.set(mapKey, log);
      }
    }
  });

  return Array.from(map.values());
}


export default Table
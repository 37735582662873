import React from "react";
import { TrophyIcon } from "./Trophy";

export default function CommissionTable(props: any) {
  const { data } = props;

  const trophyColour = (position: number) => {
    switch (position) {
      case 0:
        return "#D6AF36";
      case 1:
        return "#A7A7AD";
      case 2:
        return "#A77044";
      default:
        return "#000000";
    }
  };

  const commissionRate = (totalSales: number) => {
    if (totalSales < 250) {
      return "10%";
    }
    if (totalSales < 500) {
      return "20%";
    }
    if (totalSales > 500) {
      return "30%";
    }
  };

  const textSize = (pos: number) => {
    if (pos === 1) {
      return "text-lg";
    }
    if (pos === 2 || pos === 3) {
      return "text-base";
    }
    return "text-sm";
  };

  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Total Sales
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Commission Rate
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Commission
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-black bg-white">
                {!props.isLoading && data.length ? (
                  data.map((row: any, index: number) => (
                    <tr key={row.name}>
                      <td
                        className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 flex flex-row items-center`}
                      >
                        <span className="bg-black text-white p-1 rounded-full w-8 text-center">
                          P{index + 1}
                        </span>
                        &nbsp;
                        {index < 3 ? (
                          <TrophyIcon colour={trophyColour(index)} />
                        ) : null}
                        <span className={`${textSize(index + 1)} `}>
                          &nbsp;{row.name}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${row.totalSales}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {commissionRate(row.totalSales)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${row.commission}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      {props.isLoading ? "Loading..." : "No records found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
